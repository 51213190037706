import React from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticatedUser } from '../../../utils/utils';

export const Error404 = () => {
  const isAuthenticated = useIsAuthenticatedUser();

  return isAuthenticated ? (
    <Navigate to='/organizations' replace />
  ) : (
    <Navigate to='/login' replace />
  );
};
