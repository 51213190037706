//************************* Organizations Data Start *******************************//
export const OrganizationsColumnData = [
  { name: "srNo", title: "Sr No" },
  { name: "name", title: "Name" },
  { name: "registrationNumber", title: "Registration Number" },
  { name: "address", title: "Address" },
  { name: "adminName", title: "Admin Name" },
  { name: "adminEmail", title: "Admin Email" },
  { name: "status", title: "Status" },
  { name: "action", title: "Action" },
];

export const OrganizationsRowData = [
  {
    id: 1,
    srNo: 1,
    name: "Tech Corp",
    registrationNumber: "TC123456",
    address: "123 Tech Avenue, San Francisco, CA 94107",
    adminName: "John Doe",
    adminEmail: "john.doe@techcorp.com",
    status: true,
    action: "Edit",
  },
  {
    id: 2,
    srNo: 2,
    name: "LuxeLocker",
    registrationNumber: "LL987654",
    address: "789 Luxe Street, Los Angeles, CA 90001",
    adminName: "Jane Smith",
    adminEmail: "jane.smith@luxelocker.com",
    status: true,
    action: "Edit",
  },
  {
    id: 3,
    srNo: 3,
    name: "GreenTech",
    registrationNumber: "GT654321",
    address: "456 Green Lane, Seattle, WA 98101",
    adminName: "Alice Johnson",
    adminEmail: "alice.johnson@greentech.com",
    status: false,
    action: "Edit",
  },
  {
    id: 4,
    srNo: 4,
    name: "City Hospital",
    registrationNumber: "CH111213",
    address: "900 Lake Drive, Chicago, IL 60611",
    adminName: "David Brown",
    adminEmail: "david.brown@cityhospital.com",
    status: true,
    action: "Edit",
  },
];

export const OrganizationsColumnExtensionsData = [
  { columnName: "srNo", width: 60 },
  { columnName: "name", width: 150 },
  { columnName: "registrationNumber", width: 150 },
  { columnName: "address", width: 200 },
  { columnName: "adminName", width: 120 },
  { columnName: "adminEmail", width: 150 },
  { columnName: "status", width: 80 },
  { columnName: "action", width: 100 },
];

//************************* Organizations Data End *******************************//

//************************* Roles and Permissions Data Start *******************************//
export const RolesColumnData = [
  { name: "id", title: "Sr No" },
  { name: "roleName", title: "Role Name" },
  { name: "modulesAssigned", title: "Modules Assigned" },
  // { name: 'permissionsAssigned', title: 'Permissions Assigned' },
  { name: "numberOfUsers", title: "Number of Users" },
  { name: "status", title: "Status" },
  { name: "action", title: "Action" },
];

export const RolesRowData = [
  {
    id: 1,
    roleName: "Admin",
    modulesAssigned: "Dashboard, Users, Reports",
    permissionsAssigned: "Create, Edit, Delete",
    numberOfUsers: 5,
    status: "Active",
    action: "View/Edit/Delete",
  },
  {
    id: 2,
    roleName: "Manager",
    modulesAssigned: "Dashboard, Users",
    permissionsAssigned: "View, Edit",
    numberOfUsers: 10,
    status: "Active",
    action: "View/Edit/Delete",
  },
  {
    id: 3,
    roleName: "Support",
    modulesAssigned: "Tickets",
    permissionsAssigned: "View",
    numberOfUsers: 15,
    status: "Inactive",
    action: "View/Edit/Delete",
  },
  {
    id: 4,
    roleName: "Guest",
    modulesAssigned: "Dashboard",
    permissionsAssigned: "View",
    numberOfUsers: 20,
    status: "Active",
    action: "View/Edit/Delete",
  },
];

export const RolesColumnExtensionsData = [
  { columnName: "id", width: 50 }, // Added width for the ID column
  { columnName: "roleName", width: 150 },
  { columnName: "modulesAssigned", width: 200 },
  { columnName: "permissionsAssigned", width: 200 },
  { columnName: "numberOfUsers", width: 120 },
  { columnName: "status", width: 80 },
  { columnName: "action", width: 120 },
];

//************************* Roles and Permissions Data End *******************************//
//************************* Users Data Start *******************************//
export const UsersColumnData = [
  { name: "avatar_url", title: "Avatar" },
  { name: "full_name", title: "Full Name" },
  { name: "username", title: "Username" },
  { name: "roleName", title: "Role Name" },
  { name: "email", title: "Email" },
  { name: "phone", title: "Phone Number" },
  { name: "address", title: "Address" },
  { name: "positionTitle", title: "Position Board Title" },
  { name: "status", title: "Status" },
  { name: "action", title: "Action" },
];

export const UsersRowData = [
  {
    id: 1,
    userName: "Michael Scott",
    roleName: "Admin",
    constituentId: "CS123456",
    email: "michael.scott@dundermifflin.com",
    status: "Active",
    action: "View/Edit/Delete",
  },
  {
    id: 2,
    userName: "Pam Beesly",
    roleName: "Support",
    constituentId: "CS654321",
    email: "pam.beesly@dundermifflin.com",
    status: "Active",
    action: "View/Edit/Delete",
  },
  {
    id: 3,
    userName: "Jim Halpert",
    roleName: "Manager",
    constituentId: "CS112233",
    email: "jim.halpert@dundermifflin.com",
    status: "Active",
    action: "View/Edit/Delete",
  },
  {
    id: 4,
    userName: "Dwight Schrute",
    roleName: "Manager",
    constituentId: "CS445566",
    email: "dwight.schrute@dundermifflin.com",
    status: "Inactive",
    action: "View/Edit/Delete",
  },
  {
    id: 5,
    userName: "Stanley Hudson",
    roleName: "Guest",
    constituentId: "CS778899",
    email: "stanley.hudson@dundermifflin.com",
    status: "Active",
    action: "View/Edit/Delete",
  },
];

export const UsersColumnExtensionsData = [
  { columnName: "id", width: 50 },
  { columnName: "userName", width: 150 },
  { columnName: "roleName", width: 150 },
  { columnName: "constituentId", width: 150 },
  { columnName: "email", width: 200 },
  { columnName: "status", width: 80 },
  { columnName: "action", width: 120 },
  { columnName: "positionTitle", width: 150 },
];

//************************* Users Data End *******************************//
