// Library import
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch } from 'react-redux';

// Icons Imports
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Visibility, Edit, Delete } from "@mui/icons-material";

// Local imports
import {
  CustomTable,
  CustomButton,
  CustomPopover,
  PopoverData,
  CustomModal,
  CustomDivider,
  TextInput,
  CustomCheckbox,
  Spinner,
  CustomSnackbar,
} from "../../components/shared";
import {
  RolesColumnData,
  RolesRowData,
  RolesColumnExtensionsData,
} from "../../components/shared/CustomTable/dummyData";
import { roleListing } from "../../redux/reducers/rolesAndPermissions/roleListing";
import { createOrUpdateRole } from "../../redux/reducers/rolesAndPermissions/addUpdateRole";
import { deleteRole } from "../../redux/reducers/rolesAndPermissions/addUpdateRole";
import { useDispatch, useSelector } from "react-redux";
import { permissionsListing } from "../../redux/reducers/rolesAndPermissions/prmissionsList";

const RoleAndPermission = () => {
  const [ColumnSetting1] = useState(["status"]);
  const [ColumnSetting2] = useState(["action"]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.permissionListing);
  const deleteRoleStore = useSelector((state) => state?.deleteRole);

  const rollListing = useSelector((state) => state?.roleListing);
  const addRoleStore = useSelector((state) => state?.addUpdateRole);
  const [errorsShow, setErrorsShow] = useState(false);
  const [rowData, setRowData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "20";

  useEffect(() => {
    dispatch(permissionsListing({ pageNumber, pageSize }));
    dispatch(roleListing());
  }, [dispatch, pageNumber, pageSize]);

  useEffect(() => {
    if (errorsShow?.success) {
      setIsAddEditModalOpen(false);
      dispatch(roleListing());
      handleCloseModal();
    }
  }, [errorsShow]);

  // Popover Data
  const popoverData = [
    {
      icon: (
        <Visibility
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "View", sx: { pt: "1px" } },
      onClick: (restProps) => {
        setIsViewModalOpen(true);
        setRowData(restProps);
      },
    },
    {
      icon: (
        <Edit
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "Edit", sx: { pt: "1px" } },
      onClick: (restProps) => {
        setIsAddEditModalOpen(true);
        setRowData(restProps);
        console.log("restProps", restProps);
        setFormData({
          uuid: restProps?.row?.uuid || "",
          name: restProps?.row?.roleName || "",
          permission_ids:
            restProps?.row?.permissionsAssigned === "No Permissions"
              ? []
              : restProps?.row?.permissionsAssigned?.map(
                  (permission) => permission?.uuid
                ) || [],
        });
      },
    },
    {
      icon: (
        <Delete
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "Delete", sx: { pt: "1px" } },
      onClick: (restProps) => {
        setIsDeleteModalOpen(true);
        setRowData(restProps);
      },
    },
  ];

  // Action
  const Action = (restProps) => {
    return (
      <>
        <Box component={"div"} className="flex flex-row justify-end pr-5">
          <CustomPopover
            // success={errorsShow?.success ? errorsShow?.success : modalOpen}
            trigger={
              <MoreVertIcon sx={{ color: "black", fontSize: "large" }} />
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            success={isViewModalOpen || isAddEditModalOpen || isDeleteModalOpen}
          >
            <Box minWidth={"127px"} p={1}>
              {popoverData?.map(({ icon, label, onClick }, index) => (
                <Box
                  key={index}
                  component={"div"}
                  display={"flex"}
                  flexDirection={"column"}
                  py={"3px"}
                  px={"5px"}
                  onClick={() => onClick(restProps, label)}
                >
                  <PopoverData icon={icon} label={label} />
                </Box>
              ))}
            </Box>
          </CustomPopover>
        </Box>
      </>
    );
  };

  const dataProviders = [
    {
      columnName: ColumnSetting2,
      func: (restProps) => Action(restProps),
    },
    {
      columnName: ColumnSetting1,
      func: (restProps) => {
        const status = restProps?.row?.status;
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {status === "Active" ? (
              <div
                style={{
                  backgroundColor: "#50d950",
                  width: "fit-content",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  color: "#ffffff",
                }}
              >
                {status}
              </div>
            ) : null}
            {status === "Inactive" ? (
              <div
                style={{
                  backgroundColor: "#EA5455",
                  width: "fit-content",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  color: "#ffffff",
                }}
              >
                {status}
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  //..................Add, Edit Role and permission start .....................................
  const [formData, setFormData] = useState({
    uuid: "",
    name: "",
    permission_ids: [],
  });
  console.log("formData", formData);
  const handleCloseModal = () => {
    // handleClose();
    setRowData();
    setIsViewModalOpen(false);
    setIsAddEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setFormData({
      uuid: "",
      name: "",
      permission_ids: [],
    });
  };
  const handleCheckbox = (event, parentId, childId, isSelectAll = false) => {
    const { checked } = event.target;
    setFormData((prev) => {
      let updatedPermissions = [...(prev.permission_ids || [])];

      if (isSelectAll) {
        const childPermissions = store?.response?.payload
          ?.find(({ uuid }) => uuid === parentId)
          ?.children.map((child) => child.uuid);

        updatedPermissions = checked
          ? [...new Set([...updatedPermissions, ...childPermissions])]
          : updatedPermissions.filter((id) => !childPermissions.includes(id));
      } else {
        updatedPermissions = checked
          ? [...updatedPermissions, childId]
          : updatedPermissions.filter((id) => id !== childId);
      }

      return {
        ...prev,
        permission_ids: updatedPermissions,
      };
    });
  };

  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted FormData:", formData);

    try {
      const resultAction = await dispatch(
        createOrUpdateRole({
          uuid: rowData ? formData?.uuid : undefined,
          name: formData?.name,
          permission_ids: formData?.permission_ids,
        })
      );
      setErrorsShow({
        isError: resultAction?.error ? true : false,
        message: resultAction?.payload,
        success: resultAction?.payload?.success,
      });
    } catch (error) {
      console.error("Unexpected error during role operation:", error);
    }
  };
  //.................. Edit Role end .....................................
  const isPermissionChecked = (childId) =>
    formData.permission_ids.includes(childId);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "closeButtonClick") {
      setErrorsShow({
        isError: false,
        message: "",
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: "",
      });
    }, 2000);
  };
  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message?.message || errorsShow?.message}
        handleClose={handleClose}
      />

      {/* View Modal  */}
      <CustomModal
        open={isViewModalOpen}
        title={"Role and permissions Details"}
        close={handleCloseModal}
        saveButton="null"
        saveButtonSx={{
          width: "170px",
        }}
      >
        <Box py={4}>
          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Role Name:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.roleName || "N/A"}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Module Assigned:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.modulesAssigned || "N/A"}
            </Typography>
          </Box>

          {/* <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Permission Assigned:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.permissionsAssigned || "N/A"}
            </Typography>
          </Box> */}

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="medium">
              Status:
            </Typography>
            <Typography
              variant="body1"
              color={
                rowData?.row?.status === "Active"
                  ? "success.main"
                  : "error.main"
              }
            >
              {rowData?.row?.status || "N/A"}
            </Typography>
          </Box>
        </Box>
      </CustomModal>

      {/* Add Edit Modal  */}
      <CustomModal
        open={isAddEditModalOpen}
        handleSave={handleSubmit}
        loading={addRoleStore?.loading}
        title={
          rowData ? "Edit Role and permissions" : "Add Role and permissions"
        }
        close={handleCloseModal}
        maxWidth={{ xxs: "265px", xs: "500px" }}
        minWidth={{ xxs: "265px", xs: "500px" }}
        saveButtonSx={{ xxs: "100%", xs: "9px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          component="form"
          onSubmit={handleSubmit}
        >
          <Box backgroundColor="white" borderRadius="5px" maxHeight="565px">
            <Box mb="15px" p="20px 0px">
              {/* Heading  */}
              <Typography
                variant="h4"
                color="primary.darkGray"
                fontSize="16px"
                fontWeight={500}
              >
                {rowData ? "Edit Role" : "Add Role"}
              </Typography>
              {/* <Typography component='p' color='primary.gray' fontSize='12px'>
            Adding a role to the role list
          </Typography> */}

              {/* Body  */}
              <Box
                display="flex"
                flexDirection="column"
                gap={{ xxs: 0, xm: 1 }}
                pt={1}
              >
                {/* Add Role Input  */}
                <Grid container spacing={{ xm: 2 }}>
                  <Grid item xxs={12}>
                    <TextInput
                      name="name"
                      placeholder="Enter Role Name"
                      type="text"
                      onChange={handleInput}
                      value={formData.name}
                    />
                  </Grid>
                </Grid>

                {/* Role Permissions */}
                <Typography
                  component="p"
                  fontSize="14px"
                  color="primary.gray"
                  mt="20px"
                  fontWeight={500}
                  mb={{ xxs: "10px", xm: 0 }}
                >
                  {rowData
                    ? "Edit Permissions For Role"
                    : "Add Permissions For Role"}
                </Typography>

                {store?.response?.payload?.map(
                  ({ uuid: parentId, name, children }, index) => (
                    <Box key={index} width="100%">
                      <Grid container spacing={{ xm: 1 }}>
                        <Grid item xxs={12} sm={4}>
                          <Typography
                            component="p"
                            fontSize="12px"
                            color="primary.gray"
                            marginBottom={{ sx: "15px" }}
                            marginTop="10px"
                            fontWeight={500}
                          >
                            {name}
                          </Typography>
                        </Grid>

                        <Grid item xxs={12} sm={9} base={8}>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            gap={{ xxs: 0, sm: 3 }}
                          >
                            {name === "Administrator Access" && (
                              <CustomCheckbox
                                checked={children.every((child) =>
                                  isPermissionChecked(child.uuid)
                                )}
                                onChange={(e) =>
                                  handleCheckbox(e, parentId, null, true)
                                }
                                label="Select All"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            )}

                            {children.map(({ uuid: childId, name }) => (
                              <CustomCheckbox
                                key={childId}
                                checked={formData.permission_ids.includes(
                                  childId
                                )}
                                onChange={(e) =>
                                  handleCheckbox(e, parentId, childId)
                                }
                                label={name}
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "12px",
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                      <CustomDivider />
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      {/* Delete Modal  */}
      <CustomModal
        open={isDeleteModalOpen}
        title={"Delete Role and permissions"}
        close={handleCloseModal}
        primaryButtonText={"Delete"}
        handleSave={async () => {
          try {
            const resultAction = await dispatch(
              deleteRole({ uuid: rowData?.row?.uuid })
            );
            setErrorsShow({
              isError: resultAction?.error ? true : false,
              message: resultAction?.payload?.message || "Operation completed.",
              success: resultAction?.payload?.success || false,
            });
          } catch (error) {
            console.error("Unexpected error during role operation:", error);
            setErrorsShow({
              isError: true,
              message: "An unexpected error occurred.",
              success: false,
            });
          }
        }}
        loading={deleteRoleStore?.loading}
        saveButtonSx={{
          width: "100px",
        }}
      >
        <Typography mt={2} color="primary.darkGray">
          Are you sure you want to delete this?
        </Typography>
      </CustomModal>

      <Box>
        <Box mt={"15px"} px={{ xxs: "10px", base: "20px" }}>
          <Box display={"flex"} justifyContent={"end"}>
            <CustomButton
              variant={"contained"}
              onClick={() => setIsAddEditModalOpen(true)}
            >
              <Typography>Add Role</Typography>
            </CustomButton>
          </Box>

          <Box height={"calc(100vh - 130px)"} overflow={"auto"} mt={2}>
            {rollListing?.loading ? (
              <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Spinner size={50} />
              </Box>
            ) : (
              <CustomTable
                rows={rollListing.response?.roles}
                columns={RolesColumnData}
                tableColumnExtensions={RolesColumnExtensionsData}
                dataProviders={dataProviders}
                // isSelectedCampus={selectedIds}
                metaData={rollListing.response?.meta}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RoleAndPermission;
