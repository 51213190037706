import * as React from "react";

export const CalanderIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 2.25H12.75V1.5C12.75 1.05 12.45 0.75 12 0.75C11.55 0.75 11.25 1.05 11.25 1.5V2.25H6.75V1.5C6.75 1.05 6.45 0.75 6 0.75C5.55 0.75 5.25 1.05 5.25 1.5V2.25H3.75C2.475 2.25 1.5 3.225 1.5 4.5V15C1.5 16.275 2.475 17.25 3.75 17.25H14.25C15.525 17.25 16.5 16.275 16.5 15V4.5C16.5 3.225 15.525 2.25 14.25 2.25ZM3.75 3.75H5.25V4.5C5.25 4.95 5.55 5.25 6 5.25C6.45 5.25 6.75 4.95 6.75 4.5V3.75H11.25V4.5C11.25 4.95 11.55 5.25 12 5.25C12.45 5.25 12.75 4.95 12.75 4.5V3.75H14.25C14.7 3.75 15 4.05 15 4.5V6.75H3V4.5C3 4.05 3.3 3.75 3.75 3.75ZM3.75 15.75H14.25C14.7 15.75 15 15.45 15 15V8.25H3V15C3 15.45 3.3 15.75 3.75 15.75Z"
      fill="black"
    />
    <mask
      id="mask0_225_13349"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={0}
      width={16}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 2.25H12.75V1.5C12.75 1.05 12.45 0.75 12 0.75C11.55 0.75 11.25 1.05 11.25 1.5V2.25H6.75V1.5C6.75 1.05 6.45 0.75 6 0.75C5.55 0.75 5.25 1.05 5.25 1.5V2.25H3.75C2.475 2.25 1.5 3.225 1.5 4.5V15C1.5 16.275 2.475 17.25 3.75 17.25H14.25C15.525 17.25 16.5 16.275 16.5 15V4.5C16.5 3.225 15.525 2.25 14.25 2.25ZM3.75 3.75H5.25V4.5C5.25 4.95 5.55 5.25 6 5.25C6.45 5.25 6.75 4.95 6.75 4.5V3.75H11.25V4.5C11.25 4.95 11.55 5.25 12 5.25C12.45 5.25 12.75 4.95 12.75 4.5V3.75H14.25C14.7 3.75 15 4.05 15 4.5V6.75H3V4.5C3 4.05 3.3 3.75 3.75 3.75ZM3.75 15.75H14.25C14.7 15.75 15 15.45 15 15V8.25H3V15C3 15.45 3.3 15.75 3.75 15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_225_13349)">
      <rect width={18} height={18} fill="currentColor" />
    </g>
  </svg>
);
