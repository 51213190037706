// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = { response: null, loading: false, error: null };

// Roll listing
export const roleListing = createAsyncThunk(
  "roleListing",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(endPoints.roleListing);
      const roles = response?.data?.data?.roles;
      const pagination = response?.data?.data?.meta;

      const formattedRoles = roles.map((role, index) => ({
        id: index + 1,
        roleName: role.name || "N/A",
        modulesAssigned:
          role?.permissions
            ?.reduce((prev, curr) => {
              return prev + (curr.module ? `${curr.module}, ` : "");
            }, "")
            .slice(0, -2) || "--",
        permissionsAssigned: role.permissions.length
          ? role.permissions
          : "No Permissions",
        numberOfUsers: role.userCount || 0,
        status: index % 2 === 0 ? "Active" : "Inactive", 
        uuid: role.uuid,
      }));
      

      return { roles: formattedRoles, meta: pagination };
    } catch (error) {
      console.error("Role listing failed:", error);
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

const roleListingSlice = createSlice({
  name: "roleListing",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(roleListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(roleListing.fulfilled, (state, action) => {
        state.loading = false;
        state.response = {
          roles: action.payload.roles,
          meta: action.payload.meta,
        };
      })
      .addCase(roleListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch roles.";
      });
  },
});

export default roleListingSlice.reducer;
