// Library import
import React from "react";
import { Navigate } from "react-router-dom";

// Icons imports
import {
  DashboardIcon,
  RoleAndPermissionIcon,
  OrganizationIcon,
  AnalyticsIcon,
  FundraisingIcon,
  UsersIcon,
  ChatRoomIcon,
  DirectMessagesIcon,
  SharedDataIcon,
  CampHQSchoolIcon,
  TaskIcon,
  CalanderIcon,
  CollabrationIcon,
  SettingIcon,
} from "../assets/Icons";

// Local import
import { AuthLayout, ComingSoon, PanelLayout } from "../components/shared";
import Login from "../panel/Auth/Login";
import SignUp from "../panel/Auth/SignUp";
import OrganizationsListing from "../panel/Organizations/OrganizationsListing";
import AddOrganizations from "../panel/Organizations/AddOrganizations";
import RoleAndPermission from "../panel/RoleAndPermission";
import ForgotPassword from "../panel/Auth/ForgotPassword";
import ResetPassword from "../panel/Auth/ResetPassword";
import { Error404 } from "../components/shared";
import AddRole from "../panel/RoleAndPermission/AddRole";
import Users from "../panel/Users";
import AddUser from "../panel/Users/AddUsers";

// SIDE MENU ROUTES
export const sideMenuRoutes = [
  {
    path: "/dashboard",
    moduleName: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/organizations",
    moduleName: "Organizations",
    icon: <OrganizationIcon />,
  },
  {
    path: "/roles-and-permissions",
    moduleName: "Roles and Permissions",
    icon: <RoleAndPermissionIcon />,
  },
  {
    path: "/analytics",
    moduleName: "Analytics",
    icon: <AnalyticsIcon />,
  },
  {
    path: "/fundraising",
    moduleName: "Fundraising",
    icon: <FundraisingIcon />,
  },
  {
    path: "/users",
    moduleName: "Users",
    icon: <UsersIcon />,
  },
  {
    path: "/chat-room",
    moduleName: "Chat Room",
    icon: <ChatRoomIcon />,
  },
  {
    path: "/direct-messages",
    moduleName: "Direct Messages",
    icon: <DirectMessagesIcon />,
  },
  {
    path: "/shared-data",
    moduleName: "Shared Data",
    icon: <SharedDataIcon />,
  },
  {
    path: "/camp-hq-school",
    moduleName: "Camp HQ School",
    icon: <CampHQSchoolIcon />,
  },
  {
    path: "/tasks",
    moduleName: "Tasks",
    icon: <TaskIcon />,
  },
  {
    path: "/calendar",
    moduleName: "Calendar",
    icon: <CalanderIcon />,
  },
  {
    path: "/collaboration",
    moduleName: "Collaboration",
    icon: <CollabrationIcon />,
  },
  {
    path: "/settings",
    moduleName: "Settings",
    icon: <SettingIcon />,
  },
];

// super Admin Routes
export const superAdminRoutes = [
  {
    path: "/dashboard",
    moduleName: "Dashboard",
    element: <PanelLayout>Dashboard</PanelLayout>,
    icon: <DashboardIcon />,
  },

  {
    path: "/organizations",
    moduleName: "Organizations",
    element: (
      <PanelLayout>
        <OrganizationsListing />
      </PanelLayout>
    ),
    icon: <OrganizationIcon />,
    child: [
      {
        path: "/organizations/organization",
        moduleName: "Organizations",
        element: (
          <PanelLayout>
            <AddOrganizations />
          </PanelLayout>
        ),
      },
    ],
  },
];

// company Admin Routes
export const companyAdminRoutes = [
  {
    path: "/dashboard",
    moduleName: "Dashboard",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <DashboardIcon />,
  },
  {
    path: "/roles-and-permissions",
    moduleName: "Roles and Permissions",
    element: (
      <PanelLayout>
        <RoleAndPermission />
      </PanelLayout>
    ),
    icon: <RoleAndPermissionIcon />,
    child: [
      {
        path: "/roles-and-permissions/add-role",
        moduleName: "Add Role",
        element: (
          <PanelLayout>
            <AddRole />
          </PanelLayout>
        ),
      },
    ],
  },
  {
    path: "/analytics",
    moduleName: "Analytics",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <AnalyticsIcon />,
  },
  {
    path: "/fundraising",
    moduleName: "Fundraising",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <FundraisingIcon />,
  },
  {
    path: "/users",
    moduleName: "Users",
    element: (
      <PanelLayout>
        <Users />
      </PanelLayout>
    ),
    icon: <UsersIcon />,
    child: [
      {
        path: "/users/user-details",
        moduleName: "User Details",
        element: (
          <PanelLayout>
            <AddUser />
          </PanelLayout>
        ),
      },
    ],
  },
  {
    path: "/chat-room",
    moduleName: "Chat Room",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <ChatRoomIcon />,
  },
  {
    path: "/direct-messages",
    moduleName: "Direct Messages",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <DirectMessagesIcon />,
  },
  {
    path: "/shared-data",
    moduleName: "Shared Data",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <SharedDataIcon />,
  },
  {
    path: "/camp-hq-school",
    moduleName: "Camp HQ School",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <CampHQSchoolIcon />,
  },
  {
    path: "/tasks",
    moduleName: "Tasks",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <TaskIcon />,
  },
  {
    path: "/calendar",
    moduleName: "Calendar",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <CalanderIcon />,
  },
  {
    path: "/collaboration",
    moduleName: "Collaboration",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <CollabrationIcon />,
  },
  {
    path: "/settings",
    moduleName: "Settings",
    element: (
      <PanelLayout>
        <ComingSoon />
      </PanelLayout>
    ),
    icon: <SettingIcon />,
  },
];

// PUBLIC ROUTES
export const publicRoutes = [
  {
    path: "/",
    moduleName: "Base",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/login",
    moduleName: "Login",
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: "/signup",
    moduleName: "Signup",
    element: (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
  },
  {
    path: "/forgot-password",
    moduleName: "Forgot password",
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/reset-password",
    moduleName: "Reset Password",
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

// OVERALL ROUTES
export const routes = {
  super_admin: superAdminRoutes,
  company_admin: companyAdminRoutes,
};
