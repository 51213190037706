// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = { response: null, loading: false, error: null };

// Users listing
export const usersListing = createAsyncThunk(
  "usersListing",
  async (queryParams = {}, { rejectWithValue }) => {
    try {
      const params = {
        search: queryParams.search || "",
        uuid: queryParams.uuid || "",
        "role_id[]": queryParams.roleIds || [],
        "position_board_id[]": queryParams.positionBoardIds || [],
      };

      const response = await axiosInstance.get(endPoints.usersListing, {
        params,
      });
      const { users, meta: pagination } = response?.data?.data;
      const formattedUsers = users.map((user, index) => ({
        id: index + 1,
        uuid: user.uuid,
        full_name: user.full_name,
        username: user.username,
        roleName: user.role?.name,
        email: user.email,
        phone: user.phone,
        address: user.address,
        status: user.status === 1 ? "Active" : "Inactive",
        positionTitle: user.position_board?.title || "N/A",
        avatar_url: user.avatar_url || "No Avatar",
        roleID: user.role?.uuid,
        positionID: user.position_board?.uuid,
        term_start_date: user?.term_start_date,
        term_end_date: user?.term_end_date,
      }));

      return { users: formattedUsers, meta: pagination };
    } catch (error) {
      console.error("Users listing failed:", error);

      // Handle errors gracefully
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

const usersListingSlice = createSlice({
  name: "usersListing",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(usersListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(usersListing.fulfilled, (state, action) => {
        state.loading = false;
        state.response = {
          users: action.payload.users,
          meta: action.payload.meta,
        };
      })
      .addCase(usersListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch users.";
      });
  },
});

export default usersListingSlice.reducer;
