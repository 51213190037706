// Library Imports
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextInput } from '../../../components/shared';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';


// Icons Imports
import { Add } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { organizationListing } from '../../../redux/reducers/organizations/organizationListing';
import CancelIcon from '@mui/icons-material/Cancel';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

// Local imports
import {
  CustomTable,
  Spinner,
  CustomButton,
  CustomPopover,
  PopoverData,
  CustomSnackbar,
  CustomModal,
  CustomDatePicker,
  CustomAvatar,
} from '../../../components/shared';
import {
  OrganizationsColumnData,
  OrganizationsColumnExtensionsData,
  OrganizationsRowData,
} from '../../../components/shared/CustomTable/dummyData';
import { updateOrganization } from '../../../redux/reducers/organizations/updateOrganization';

const OrganizationsListing = () => {
  // States
  const [errorsShow, setErrorsShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState(false);
  const [ColumnSetting1] = useState(['action']);
  const [ColumnSetting2] = useState(['status']);
  const [ColumnSetting3] = useState(['name']);
  const store = useSelector((state) => state);
  const listing = store.organizationListing;
  const listingRowData = listing?.response?.organizations;
  const paginationData = listing?.response?.meta;
  const updateOrganizationStore = store?.updateOrganization;
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get('page') || '1';
  const pageSize = searchParams.get('pageSize') || '20';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //***** Methods *****//
  const dataProviders = [
    {
      columnName: ColumnSetting1,
      func: (restProps) => Action(restProps),
    },
    {
      columnName: ColumnSetting2,
      func: (restProps) => {
        const status = restProps?.row?.status;
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {status === 'active' ? (
              <div
                style={{
                  backgroundColor: '#50d950',
                  width: 'fit-content',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  textTransform: 'capitalize',
                  color: '#ffffff',
                }}
              >
                {status}
              </div>
            ) : null}
            {status === 'cancel' ? (
              <div
                style={{
                  backgroundColor: '#EA5455',
                  width: 'fit-content',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  textTransform: 'capitalize',
                  color: '#ffffff',
                }}
              >
                {status}
              </div>
            ) : null}
            {status === 'in-processing' ? (
              <div
                style={{
                  backgroundColor: '#00aaff',
                  width: 'fit-content',
                  padding: '4px 5px',
                  borderRadius: '4px',
                  textTransform: 'capitalize',
                  color: '#ffffff',
                }}
              >
                {status}
              </div>
            ) : null}
            {status === 'pause' ? (
              <div
                style={{
                  backgroundColor: '#F5E050',
                  width: 'fit-content',
                  padding: '4px 5px',
                  borderRadius: '4px',
                  textTransform: 'capitalize',
                  color: '#5E5873',
                }}
              >
                {status}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      columnName: ColumnSetting3,
      func: (restProps) => {
        return (
          <CustomAvatar
            userName={restProps?.row?.name}
            url={restProps?.row?.avatar}
            reverse
            avatarSX={{ width: '32px', height: '32px' }}
            userNameSx={{ fontSize: '12px' }}
          />
        );
        
      },
    },
  ];
  // UseEffect
  useEffect(() => {
    dispatch(organizationListing({pageNumber,pageSize}));
  }, [pageNumber,pageSize]);

  useEffect(() => {
    if (errorsShow?.success) {
      setFormData((prevState) => ({
        ...prevState,
        organization_id: '',
        pause_start_date: '',
        number_of_weeks: '',
        status: '',
      }));
      dispatch(organizationListing());
      setModalOpen(false);
    }
  }, [errorsShow?.success]);

  // Handle Subscription
  const handleSubmitSubscription = async (restProps, label) => {
    let status;
    if (label?.text === 'Renew Subscription') {
      status = 'in-processing';
    } else if (label?.text === 'Pause Subscription') {
      status = 'pause';
    } else if (label?.text === 'Cancel Subscription') {
      status = 'cancel';
    }

    const resultAction = await dispatch(
      updateOrganization({
        data: {
          organization_id: restProps?.row?.uuid,
          status: status,
        },
      })
    );
    // Set error state based on the action result
    setErrorsShow({
      isError: resultAction?.error ? true : false,
      message: resultAction?.payload?.message,
      success: resultAction?.payload?.success,
    });
  };

  // {........................Pause subscription start...........................}
  const [formData, setFormData] = useState({
    organization_id: '',
    pause_start_date: '',
    number_of_weeks: '',
    status: '',
  });

  const disabled =
    formData?.number_of_weeks === '' || formData?.subscription?.Action === ''
      ? true
      : false;

  // Handle input changes
  const handlePauseInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (key, date) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: dayjs(date).format('YYYY-MM-DD'),
    }));
  };
  const handleCloseModal = () => {
    setFormData((prevState) => ({
      ...prevState,
      organization_id: '',
      pause_start_date: '',
      number_of_weeks: '',
      status: '',
    }));
    handleClose();
    setModalOpen(false);
  };

  const handlePauseSubscription = (restProps, label) => {
    setFormData((prevState) => ({
      ...prevState,
      organization_id: restProps?.row?.uuid,
      status: 'pause',
    }));
    setModalOpen(true);
  };

  const handleSubmitPauseSubscription = async () => {
    const resultAction = await dispatch(
      updateOrganization({
        data: {
          organization_id: formData.organization_id,
          status: formData?.status,
          pause_start_date: formData?.pause_start_date,
          pause_weeks: formData?.number_of_weeks,
        },
      })
    );
    
    // Set error state based on the action result
    setErrorsShow({
      isError: resultAction?.error ? true : false,
      message: resultAction?.payload?.message,
      success: resultAction?.payload?.success,
    });
  };
  // {........................Pause subscription end...........................}

  // Popover Data
  const popoverData = [
    {
      icon: (
        <PauseCircleFilledIcon
          fontSize='small'
          sx={{ color: 'primary.gray', height: '18px', width: '18px' }}
        />
      ),
      label: { text: 'Pause Subscription', sx: { pt: '1px' } },
      onClick: handlePauseSubscription,
    },
    {
      icon: (
        <NotStartedIcon
          fontSize='small'
          sx={{ color: 'primary.gray', height: '18px', width: '18px' }}
        />
      ),
      label: { text: 'Renew Subscription', sx: { pt: '1px' } },
      onClick: handleSubmitSubscription,
    },
    {
      icon: (
        <CancelIcon
          fontSize='small'
          sx={{ color: 'primary.gray', height: '18px', width: '18px' }}
        />
      ),
      label: { text: 'Cancel Subscription', sx: { pt: '1px' } },
      onClick: handleSubmitSubscription,
    },
  ];

  // Action
  const Action = (restProps) => {
    return (
      <>
        <Box component={'div'} className='flex flex-row justify-end pr-5'>
          <CustomPopover
            success={errorsShow?.success ? errorsShow?.success : modalOpen}
            trigger={
              <MoreVertIcon sx={{ color: 'black', fontSize: 'large' }} />
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box minWidth={'127px'} p={1}>
              {popoverData?.map(({ icon, label, onClick }, index) => (
                <Box
                  key={index}
                  component={'div'}
                  display={'flex'}
                  flexDirection={'column'}
                  py={'3px'}
                  px={'5px'}
                  onClick={() => onClick(restProps, label)}
                >
                  <PopoverData icon={icon} label={label} />
                </Box>
              ))}
            </Box>
          </CustomPopover>
        </Box>
      </>
    );
  };

  // SnackBar handleClose
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (reason === 'closeButtonClick') {
      setErrorsShow({
        isError: false,
        message: '',
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: '',
      });
    }, 2000);
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        title={'Pause Subscription'}
        close={handleCloseModal}
        handleSave={handleSubmitPauseSubscription}
        primaryButtonText={'Pause Subscription'}
        disableSave={disabled}
        loading={updateOrganizationStore?.loading}
        saveButtonSx={{
          width: '170px',
        }}
      >
        <Typography component='p' color='primary.gray' fontSize='12px'>
          The subscription will pause at the next billing period.set the number
          of week to skip
        </Typography>
        <Box
          paddingBottom={2}
          display={'flex'}
          flexDirection={'column'}
          gap={1.5}
          maxWidth={'580px'}
        >
          <CustomDatePicker
            label='Pause Start Dare'
            value={formData?.pause_start_date || ''}
            name='pause_start_date'
            handleOnChange={handleDateChange}
          />

          <TextInput
            name='number_of_weeks'
            placeholder='No of Weeks'
            type='number'
            onChange={handlePauseInputChange}
            value={formData.number_of_weeks}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
              },
            }}
          />
        </Box>
      </CustomModal>

      <CustomSnackbar
        open={
          errorsShow?.isError
            ? errorsShow?.isError
            : errorsShow?.success
            ? errorsShow?.success
            : false
        }
        error={errorsShow?.isError ? true : false}
        message={errorsShow?.message}
        handleClose={handleClose}
      />

      <Box mt={'15px'} px={{ xxs: '10px', base: '20px' }}>
        <Box display={'flex'} justifyContent={'end'}>
          <CustomButton
            variant={'contained'}
            onClick={() =>
              navigate('/organizations/organization', {
                state: { isEdit: false },
              })
            }
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
            >
              <Add sx={{ height: '20px', width: '20px' }} />
              <Typography fontSize={'14px'}>Create Organization</Typography>
            </Box>
          </CustomButton>
        </Box>

        <Box  height={'calc(100vh - 130px)'} overflow={'auto'} mt={2}>
          {listing?.loading ? (
            <Box
              width={'100%'}
              height={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Spinner size={50} />
            </Box>
          ) : (
            <CustomTable
              rows={listingRowData}
              columns={OrganizationsColumnData}
              tableColumnExtensions={OrganizationsColumnExtensionsData}
              dataProviders={dataProviders}
              isSelectedCampus={selectedIds}
              metaData={paginationData}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default OrganizationsListing;
