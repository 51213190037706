// Library import
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

// Local import
import {
  TextInput,
  CustomButton,
  SocialIcon,
  CustomDivider,
  CustomCheckbox,
  CustomLink,
} from '../../../components/shared';
import { loginUser } from '../../../redux/reducers/auth/authReducers';
import { validateEmail } from '../../../utils/validator';
import { CustomSnackbar } from '../../../components/shared';

const Login = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [errorsShow, setErrorsShow] = useState(false);
  const user = useSelector((state) => state?.auth);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  // Checkbox state
  const [checked, setChecked] = useState(false);

  // Login button disabled
  const disabled =
    formData.email === '' || formData.password === '' ? true : false;

  // Handle input change function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // clear errors if errors not present
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  // Handle submit function in the Login component
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData?.email)) {
      setErrors((prev) => ({
        ...prev,
        email: 'Invalid email format. Please enter a valid email.',
      }));
    }

    if (validateEmail(formData?.email)) {
      const resultAction = await dispatch(
        loginUser({
          email: formData.email.trim(),
          password: formData.password.trim(),
        })
      );

      setErrorsShow({
        isError: resultAction?.error ? true : false,
        message: resultAction?.payload?.message
          ? resultAction?.payload?.message
          : resultAction?.payload,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (reason === 'closeButtonClick') {
      setErrorsShow({
        isError: false,
        message: '',
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: '',
      });
    }, 2000);
  };
  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message}
        handleClose={handleClose}
      />
      <Box
        component={'form'}
        onSubmit={handleSubmit}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        marginTop={{ lg: 4 }}
        paddingX={{ xxs: '10px', md: '5px', lg: '10px', xl: '40px' }}
        marginX={'auto'}
        width={{ sm: '350px', lg: 'auto' }}
      >
        <Box paddingBottom={'5px'} textAlign={{ xxs: 'center', lg: 'left' }}>
          <Typography
            variant='h5'
            color='primary.darkGray'
            fontWeight={'bold'}
            paddingBottom={'5px'}
            fontFamily={'Montserrat'}
            fontSize={'24px'}
          >
            Please Login Here
          </Typography>
          <Typography
            variant='base'
            color='primary.gray'
            fontFamily={'Montserrat'}
            fontSize={'14px'}
          >
            Please login to your account and start the adventure
          </Typography>
        </Box>

        <TextInput
          name={'email'}
          label='Email'
          placeholder={'johndoe@gmail.com'}
          onChange={handleInputChange}
          value={formData.email}
          error={errors?.email}
        />

        <TextInput
          type={'password'}
          label='Password'
          name={'password'}
          placeholder={'Password'}
          onChange={handleInputChange}
          value={formData.password}
          error={errors?.password}
        />

        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <CustomCheckbox
            checked={checked}
            setChecked={setChecked}
            label={'Remember me'}
          />

          <Typography
            variant='base'
            color='primary.gray'
            fontFamily={'Montserrat'}
            fontSize={'14px'}
            sx={{ cursor: 'pointer' }}
          >
            <CustomLink href={'/forgot-password'}>Forgot password?</CustomLink>
          </Typography>
        </Box>

        <CustomButton
          disabled={disabled}
          variant={'contained'}
          type={'submit'}
          loading={user?.loading}
        >
          <Typography>Login</Typography>
        </CustomButton>

        {/* <Typography
        variant='subtitle'
        color='primary.gray'
        sx={{ userSelect: 'none' }}
        fontFamily={'Montserrat'}
        fontSize={'14px'}
      >
        New on our platform?{'  '}
        <CustomLink href={'/signup'}>Sign up</CustomLink>
      </Typography> */}

        <CustomDivider>or</CustomDivider>

        <SocialIcon />
      </Box>
    </>
  );
};

export default Login;
