// Library import
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch } from 'react-redux';

// Icons Imports
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Visibility, Edit, Delete } from "@mui/icons-material";

// Local imports
import {
  CustomTable,
  CustomButton,
  CustomPopover,
  PopoverData,
  CustomModal,
  Spinner,
  CustomSnackbar,
  CustomAvatar,
} from "../../components/shared";
import {
  UsersColumnData,
  UsersRowData,
  UsersColumnExtensionsData,
} from "../../components/shared/CustomTable/dummyData";
import { usersListing } from "../../redux/reducers/users/userListing";
import addUser from "../../redux/reducers/users/addUser";
import updateUser from "../../redux/reducers/users/updateUser";
import { deleteUser } from "../../redux/reducers/users/deleteUser";
import { useDispatch, useSelector } from "react-redux";

const Users = () => {
  const [ColumnSetting1] = useState(["status"]);
  const [ColumnSetting2] = useState(["action"]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errorsShow, setErrorsShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userListingStore = useSelector((state) => state?.usersListing);
  const addUserStore = useSelector((state) => state?.permissionListing);
  const updateUserStore = useSelector((state) => state?.permissionListing);
  const deleteUserStore = useSelector((state) => state?.deleteUser);

  const [rowData, setRowData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "20";
  const [ColumnSetting3] = useState(["avatar_url"]);

  useEffect(() => {
    dispatch(usersListing({ pageSize, pageNumber }));
  }, [dispatch, pageNumber, pageSize, errorsShow.success]);
  console.log("userListingStore", userListingStore?.response?.users);

  // Popover Data
  const popoverData = [
    {
      icon: (
        <Visibility
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "View", sx: { pt: "1px" } },
      onClick: (restProps) => {
        setIsViewModalOpen(true);
        setRowData(restProps);
      },
    },
    {
      icon: (
        <Edit
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "Edit", sx: { pt: "1px" } },
      onClick: (restProps) => {
        navigate("/users/user-details", { state: { ...restProps } });
      },
    },
    {
      icon: (
        <Delete
          fontSize="small"
          sx={{ color: "primary.gray", height: "18px", width: "18px" }}
        />
      ),
      label: { text: "Delete", sx: { pt: "1px" } },
      onClick: (restProps) => {
        setIsDeleteModalOpen(true);
        setRowData(restProps);
      },
    },
  ];
  console.log("-----------", errorsShow);
  // Action
  const Action = (restProps) => {
    return (
      <>
        <Box component={"div"} className="flex flex-row justify-end pr-5">
          <CustomPopover
            // success={errorsShow?.success ? errorsShow?.success : modalOpen}
            trigger={
              <MoreVertIcon sx={{ color: "black", fontSize: "large" }} />
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            success={isViewModalOpen || isDeleteModalOpen}
          >
            <Box minWidth={"127px"} p={1}>
              {popoverData?.map(({ icon, label, onClick }, index) => (
                <Box
                  key={index}
                  component={"div"}
                  display={"flex"}
                  flexDirection={"column"}
                  py={"3px"}
                  px={"5px"}
                  onClick={() => onClick(restProps, label)}
                >
                  <PopoverData icon={icon} label={label} />
                </Box>
              ))}
            </Box>
          </CustomPopover>
        </Box>
      </>
    );
  };

  const dataProviders = [
    {
      columnName: ColumnSetting2,
      func: (restProps) => Action(restProps),
    },
    {
      columnName: ColumnSetting3,
      func: (restProps) => {
        console.log('picture',restProps?.row?.avatar_url)
        return (
          <CustomAvatar
            userName={restProps?.row?.name}
            url={restProps?.row?.avatar_url}
            reverse
            avatarSX={{ width: "32px", height: "32px" }}
            userNameSx={{ fontSize: "12px" }}
          />
        );
      },
    },
    {
      columnName: ColumnSetting1,
      func: (restProps) => {
        const status = restProps?.row?.status;
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {status === "Active" ? (
              <div
                style={{
                  backgroundColor: "#50d950",
                  width: "fit-content",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  color: "#ffffff",
                }}
              >
                {status}
              </div>
            ) : null}
            {status === "Inactive" ? (
              <div
                style={{
                  backgroundColor: "#EA5455",
                  width: "fit-content",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                  color: "#ffffff",
                }}
              >
                {status}
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    // handleClose();
    setRowData();
    setIsViewModalOpen(false);
    setIsDeleteModalOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "closeButtonClick") {
      setErrorsShow({
        isError: false,
        message: "",
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: "",
      });
    }, 2000);
  };

  useEffect(() => {
    if (errorsShow.success) {
      setIsDeleteModalOpen(false);
    }
  }, [errorsShow.success]);
  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message?.message || errorsShow?.message}
        handleClose={handleClose}
      />

      <CustomModal
        open={isViewModalOpen}
        title={"User Details"}
        close={handleCloseModal}
        saveButton="null"
        saveButtonSx={{
          width: "170px",
        }}
      >
        <Box py={4}>
          {/* Displaying User Name */}
          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              User Name:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.userName || "N/A"}
            </Typography>
          </Box>

          {/* Displaying Role Name */}
          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Role Name:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.roleName || "N/A"}
            </Typography>
          </Box>

          {/* Displaying Email */}
          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Email:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.email || "N/A"}
            </Typography>
          </Box>

          {/* Displaying Phone Number */}
          <Box display="flex" justifyContent="space-between" marginBottom={1.5}>
            <Typography variant="body1" fontWeight="medium">
              Phone Number:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.phone || "N/A"}
            </Typography>
          </Box>

          {/* Displaying Address */}
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="medium">
              Address:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {rowData?.row?.address || "N/A"}
            </Typography>
          </Box>

          {/* Displaying Status */}
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1" fontWeight="medium">
              Status:
            </Typography>
            <Typography
              variant="body1"
              color={
                rowData?.row?.status === "Active"
                  ? "success.main"
                  : "error.main"
              }
            >
              {rowData?.row?.status || "N/A"}
            </Typography>
          </Box>
        </Box>
      </CustomModal>

      {/* Delete Modal  */}
      <CustomModal
        open={isDeleteModalOpen}
        title={"Delete User"}
        close={handleCloseModal}
        primaryButtonText={"Delete"}
        saveButtonSx={{
          width: "120px",
        }}
        loading={deleteUserStore?.loading}
        handleSave={async () => {
          try {
            const resultAction = await dispatch(
              deleteUser({ uuid: rowData?.row?.uuid })
            );

            setErrorsShow({
              isError: resultAction?.error ? true : false,
              message: resultAction?.payload?.message || "Operation completed.",
              success: resultAction?.payload?.success || false,
            });
          } catch (error) {
            console.error("Unexpected error during role operation:", error);
            setErrorsShow({
              isError: true,
              message: error || "An unexpected error occurred.",
              success: false,
            });
          }
        }}
      >
        <Typography mt={2} color="primary.darkGray">
          Are you sure you want to delete this user?
        </Typography>
      </CustomModal>

      <Box>
        <Box mt={"15px"} px={{ xxs: "10px", base: "20px" }}>
          <Box display={"flex"} justifyContent={"end"}>
            <CustomButton
              variant={"contained"}
              onClick={() => navigate("/users/user-details")}
            >
              <Typography>Add User</Typography>
            </CustomButton>
          </Box>

          <Box height={"calc(100vh - 130px)"} overflow={"auto"} mt={2}>
            {userListingStore?.loading ? (
              <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Spinner size={50} />
              </Box>
            ) : (
              <CustomTable
                rows={userListingStore?.response?.users}
                columns={UsersColumnData}
                tableColumnExtensions={UsersColumnExtensionsData}
                dataProviders={dataProviders}
                // isSelectedCampus={selectedIds}
                metaData={userListingStore?.response?.meta}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Users;
