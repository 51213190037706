import { Box, Typography } from '@mui/material';
import React from 'react';
import { assets } from '../../../assets/Images';

export const AuthLayout = ({ children }) => {
  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'} height={'100vh'}>
      <Box
        height={'100%'}
        display={{ xxs: 'none', lg: 'flex' }}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        bgcolor={'#eae8fc'}
        width={'100%'}
      >
        <Typography
          variant='h4'
          component={'p'}
          fontFamily={'Montserrat'}
          fontWeight={600}
          color='primary.darkGray'
        >
          Welcome to CampHQ!
        </Typography>
        <img
          src={assets?.loginImg}
          alt='Logo'
          loading='lazy'
          width={'500px'}
          height={'500px'}
          style={{ objectFit: 'contain' }}
        />
      </Box>

      <Box
        width={{
          xxs: '100%',
          lg: '480px',
          xl: '650px',
        }}
        height={'100%'}
        px={'20px'}
        ml={'auto'}
        bgcolor={'white'}
        sx={{ overflow: 'auto' }}
      >
        <Box
          py={'50px'}
          width={{ xxs: '150px', xs: '180px', md: '200px', lg: '230px' }}
          display={'flex'}
          justifyContent={'center'}
          mx={'auto'}
        >
          <img
            src={assets?.logo}
            alt='Logo'
            loading='lazy'
            width={'100%'}
            height={'100%'}
          />
        </Box>
        {children}
      </Box>
    </Box>
  );
}