import * as React from "react";

export const SettingIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.47727 19.2152L0 14.6104V5.40072L7.47727 0.795898L14.9545 5.40072V14.6104L7.47727 19.2152ZM7.47727 2.7316L1.57416 6.36606V13.645L7.47727 17.2803L13.3804 13.645V6.36606L7.47727 2.73076V2.7316ZM7.47727 13.3545C6.74888 13.3547 6.04297 13.0862 5.47982 12.5948C4.91668 12.1034 4.53113 11.4194 4.38889 10.6595C4.24665 9.89965 4.3565 9.11081 4.69974 8.42742C5.04298 7.74403 5.59836 7.20838 6.27126 6.91174C6.94415 6.6151 7.69292 6.57583 8.38999 6.80062C9.08705 7.0254 9.68927 7.50034 10.094 8.1445C10.4988 8.78866 10.6811 9.56218 10.6098 10.3333C10.5385 11.1044 10.2181 11.8253 9.70314 12.3733C9.11154 12.9993 8.31173 13.3519 7.47727 13.3545ZM7.47727 8.33106C7.27055 8.33101 7.06584 8.37427 6.87484 8.45837C6.68383 8.54247 6.51027 8.66576 6.36406 8.82121C6.21785 8.97666 6.10185 9.16123 6.02269 9.36436C5.94354 9.5675 5.90277 9.78523 5.90272 10.0051C5.90267 10.225 5.94333 10.4428 6.02239 10.646C6.10145 10.8491 6.21736 11.0338 6.3635 11.1893C6.50964 11.3448 6.68314 11.4682 6.87411 11.5524C7.06508 11.6366 7.26976 11.68 7.47649 11.68C7.89398 11.6801 8.29442 11.5038 8.5897 11.1899C8.88499 10.8759 9.05094 10.4501 9.05104 10.006C9.05115 9.56186 8.8854 9.13591 8.59026 8.8218C8.29512 8.5077 7.89477 8.33117 7.47727 8.33106Z"
      fill="currentColor"
    />
  </svg>
);
