import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { positionOnBoardListing } from "../../../redux/reducers/users/positionOnBoardListing";
import { roleListing } from "../../../redux/reducers/rolesAndPermissions/roleListing";
import { useDispatch, useSelector } from "react-redux";
import { addUser as addUserAction } from "../../../redux/reducers/users/addUser";
import {
  CustomButton,
  CustomDropdown,
  TextInput,
  ImagePicker,
  CustomDatePicker,
  CustomSwitch,
  CustomSnackbar,
} from "../../../components/shared";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const restProps = location.state;
  const [errorsShow, setErrorsShow] = useState(false);
  const addUserStore = useSelector((state) => state?.addUser);

  useEffect(() => {
    dispatch(positionOnBoardListing());
    dispatch(roleListing());
  }, [dispatch]);
  useEffect(() => {
    if (errorsShow.success) {
      navigate("/users");
    }
  }, [errorsShow.success]);

  const roleListingStore = useSelector(
    (state) => state?.roleListing?.response?.roles
  );
  const positionOnBoardStore = useSelector(
    (state) => state?.positionOnBoardListing
  );

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    username: "",
    email: "",
    phone: "",
    address: "",
    role_id: null,
    status: 1,
    position_board_id: null,
    term_start_date: "",
    term_end_date: "",
    image: null,
    uuid: null,
  });
  console.log("formData", formData);
  // Add role button disabled
  const disabled =
    formData.fname === "" ||
    formData.lname === "" ||
    formData.username === "" ||
    formData.email === "" ||
    formData.phone === "" ||
    formData.address === "" ||
    formData.role_id === null ||
    formData.status === "" ||
    formData.position_board_id === null ||
    formData.term_start_date === "" ||
    formData.term_end_date === ""||
    restProps
      ? true
      : false;

  // Example usage in your component
  const convertUrlToFile = async (url, fileName) => {
    try {
      console.log("Fetching URL:", url); // Debugging
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      console.log("Blob fetched:", blob); // Debugging
      return new File([blob], fileName, { type: "image/jpeg" });
    } catch (error) {
      console.error("Error converting URL to file:", error);
      return null;
    }
  };

  useEffect(() => {
    const updateFormData = async () => {
      let imageFile = null;

      if (restProps?.row?.avatar_url) {
        // Fix the double slash in the URL
        const fixedUrl = restProps.row.avatar_url.replace(/\/\//g, "/");
        console.log("Fixed Avatar URL:", fixedUrl); // Debugging

        imageFile = await convertUrlToFile(fixedUrl, "avatar_image.jpg");
        console.log("Converted Image File:", imageFile); // Debugging
      } else {
        console.log("No Avatar URL provided."); // Debugging
      }

      setFormData((prev) => {
        console.log("Previous Form Data:", prev); // Debugging
        const updatedData = {
          ...prev,
          fname: restProps?.row?.full_name?.split(" ")[0] || "",
          lname: restProps?.row?.full_name?.split(" ")[1] || "",
          username: restProps?.row?.username || "",
          email: restProps?.row?.email || "",
          phone: restProps?.row?.phone || "",
          address: restProps?.row?.address || "",
          role_id: restProps?.row?.roleName || "",
          status: restProps?.row?.status === "Active" ? 1 : 0 || "",
          position_board_id: restProps?.row?.positionTitle || "",
          term_start_date: prev.term_start_date || "",
          term_end_date: prev.term_end_date || "",
          image: imageFile, // Assign the converted file here
          uuid: restProps?.row?.uuid,
          term_end_date: restProps?.row?.term_end_date,
          term_start_date: restProps?.row?.term_start_date,
        };
        console.log("Updated Form Data:", updatedData); // Debugging
        return updatedData;
      });
    };

    if (restProps) {
      updateFormData();
    }
  }, [restProps]);

  useEffect(() => {
    const updateFormData = async () => {
      let imageFile = null;

      if (restProps?.row?.avatar_url) {
        imageFile = await convertUrlToFile(
          restProps.row.avatar_url,
          "avatar_image.jpg"
        );
      }

      setFormData((prev) => ({
        ...prev,
        fname: restProps?.row?.full_name?.split(" ")[0] || "",
        lname: restProps?.row?.full_name?.split(" ")[1] || "",
        username: restProps?.row?.username || "",
        email: restProps?.row?.email || "",
        phone: restProps?.row?.phone || "",
        address: restProps?.row?.address || "",
        role_id: restProps?.row?.roleName || "",
        status: restProps?.row?.status === "Active" ? 1 : 0 || "",
        position_board_id: restProps?.row?.positionTitle || "",
        term_start_date: prev.term_start_date || "",
        term_end_date: prev.term_end_date || "",
        image: imageFile || null, // Save the converted file here
        uuid: restProps?.row?.uuid,
      }));
    };

    if (restProps) {
      updateFormData();
    }
  }, [restProps]);

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (key, date) => {
    setFormData((prev) => ({
      ...prev,
      [key]: dayjs(date).format("YYYY-MM-DD"),
    }));
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();

    // Explicitly appending each field to FormData
    formDataObj.append("fname", formData.fname || "");
    formDataObj.append("lname", formData.lname || "");
    formDataObj.append("username", formData.username || "");
    formDataObj.append("email", formData.email || "");
    formDataObj.append("phone", formData.phone || "");
    formDataObj.append("address", formData.address || "");
    formDataObj.append("role_id", formData?.role_id?.value || "");
    formDataObj.append("status", formData.status || "");
    formDataObj.append(
      "position_board_id",
      formData?.position_board_id?.value || ""
    );
    formDataObj.append("term_start_date", formData.term_start_date || "");
    formDataObj.append("term_end_date", formData.term_end_date || "");
    if (formData.image) {
      try {
        const base64Image = await fileToBase64(formData.image);
        formDataObj.append("avatar_image", base64Image);
      } catch (error) {
        console.error("Error converting image to Base64:", error);
      }
    }

    try {
      const resultAction = await dispatch(addUserAction({ data: formDataObj }));

      setErrorsShow({
        isError: resultAction?.error ? true : false,
        message: resultAction?.payload,
        success: resultAction?.payload?.success,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const roles = roleListingStore?.map((role) => ({
    label: role.roleName,
    value: role.uuid,
  }));

  const positions = positionOnBoardStore?.response?.payload.map((position) => ({
    label: position.title,
    value: position.uuid,
  }));

  const handleSwitch = () => {
    setFormData((prevState) => ({
      ...prevState,
      status: prevState.status === 1 ? 0 : 1,
    }));
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "closeButtonClick") {
      setErrorsShow({
        isError: false,
        message: "",
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: "",
      });
    }, 2000);
  };
  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message?.message || errorsShow?.message}
        handleClose={handleClose}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "calc(100vh - 63px)" }}
        component="form"
        overflow={"auto"}
        onSubmit={handleSubmit}
      >
        <Box backgroundColor="white" borderRadius="5px" mx="20px" mt="25px">
          <Box mx="20px" mb="15px" p="20px 0px">
            <Typography
              variant="h4"
              color="primary.darkGray"
              fontSize="18px"
              mb={1.5}
              fontWeight={500}
            >
              {restProps ? "Edit User" : "Add User"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ImagePicker
                  setFormData={(file) =>
                    setFormData((prev) => ({
                      ...prev,
                      image: file,
                      imageUrl: null,
                    }))
                  }
                  isImage={formData.image}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="fname"
                  label="First Name"
                  placeholder="Enter first name"
                  value={formData.fname}
                  onChange={handleInputChange("fname")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="lname"
                  label="Last Name"
                  placeholder="Enter last name"
                  value={formData.lname}
                  onChange={handleInputChange("lname")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="username"
                  label="Username"
                  placeholder="Enter username"
                  value={formData.username}
                  onChange={handleInputChange("username")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="email"
                  label="Email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={handleInputChange("email")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="phone"
                  label="Phone"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={handleInputChange("phone")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="address"
                  label="Address"
                  placeholder="Enter address"
                  value={formData.address}
                  onChange={handleInputChange("address")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomDropdown
                  label="Role"
                  options={roles}
                  value={formData.role_id}
                  setValue={(value) =>
                    setFormData((prev) => ({ ...prev, role_id: value }))
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomDropdown
                  label="Position on Board"
                  options={positions}
                  value={formData.position_board_id}
                  setValue={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      position_board_id: value,
                    }))
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  label="Term Start Date"
                  value={formData.term_start_date}
                  handleOnChange={(date) =>
                    handleDateChange("term_start_date", date)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomDatePicker
                  label="Term End Date"
                  value={formData.term_end_date}
                  handleOnChange={(date) =>
                    handleDateChange("term_end_date", date)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} ml={"5px"}>
                <CustomSwitch
                  label={formData.status === 1 ? "Active" : "Inactive"}
                  active={formData.status === 1}
                  handleSwitch={handleSwitch}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          bgcolor="white"
          height="62px"
          display="flex"
          justifyContent="end"
          alignItems="center"
          px="20px"
          py="10px"
          gap={2}
        >
          <CustomButton onClick={() => navigate("/users")} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton
            type="submit"
            variant="contained"
            disabled={disabled}
            loading={addUserStore?.loading}
          >
            {restProps ? "Edit User" : "Add User"}
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default AddUser;
