import * as React from "react";

export const UsersIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 5.25C5.25 3.15 6.9 1.5 9 1.5C11.1 1.5 12.75 3.15 12.75 5.25C12.75 7.35 11.1 9 9 9C6.9 9 5.25 7.35 5.25 5.25ZM15.75 14.25V15.75C15.75 16.2 15.45 16.5 15 16.5C14.55 16.5 14.25 16.2 14.25 15.75V14.25C14.25 12.975 13.275 12 12 12H6C4.725 12 3.75 12.975 3.75 14.25V15.75C3.75 16.2 3.45 16.5 3 16.5C2.55 16.5 2.25 16.2 2.25 15.75V14.25C2.25 12.15 3.9 10.5 6 10.5H12C14.1 10.5 15.75 12.15 15.75 14.25ZM9 7.5C7.725 7.5 6.75 6.525 6.75 5.25C6.75 3.975 7.725 3 9 3C10.275 3 11.25 3.975 11.25 5.25C11.25 6.525 10.275 7.5 9 7.5Z"
      fill="black"
    />
    <mask
      id="mask0_225_13330"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={1}
      width={14}
      height={16}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 5.25C5.25 3.15 6.9 1.5 9 1.5C11.1 1.5 12.75 3.15 12.75 5.25C12.75 7.35 11.1 9 9 9C6.9 9 5.25 7.35 5.25 5.25ZM15.75 14.25V15.75C15.75 16.2 15.45 16.5 15 16.5C14.55 16.5 14.25 16.2 14.25 15.75V14.25C14.25 12.975 13.275 12 12 12H6C4.725 12 3.75 12.975 3.75 14.25V15.75C3.75 16.2 3.45 16.5 3 16.5C2.55 16.5 2.25 16.2 2.25 15.75V14.25C2.25 12.15 3.9 10.5 6 10.5H12C14.1 10.5 15.75 12.15 15.75 14.25ZM9 7.5C7.725 7.5 6.75 6.525 6.75 5.25C6.75 3.975 7.725 3 9 3C10.275 3 11.25 3.975 11.25 5.25C11.25 6.525 10.275 7.5 9 7.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_225_13330)">
      <rect width={18} height={18} fill="currentColor" />
    </g>
  </svg>
);
