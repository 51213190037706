// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = { response: null, loading: false, error: null };

// Permissions Listing Api
export const permissionsListing = createAsyncThunk(
  "permissionsListing/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(endPoints.permissionsListing);
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

// Permissions Listing Slice
const permissionsListingSlice = createSlice({
  name: "permissionsListing",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(permissionsListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(permissionsListing.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action;
      })
      .addCase(permissionsListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to load permissions";
      });
  },
});

export default permissionsListingSlice.reducer;
