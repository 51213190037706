/***************************** Email REGEX *****************************/

export const validateEmail = (email) => {
  var emailRegex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return emailRegex.test(email);
};

/***************************** Password Validation REGEX *****************************/

export const validatePassword = (password) => {
  var passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+=<>?{}[\]~`|\\:;"',./]).{6,}$/;
  return passwordRegex.test(password);
};
