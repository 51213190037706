// Library Imports
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextInput } from '../../../../components/shared';
import { CustomDatePicker } from '../../../../components/shared';
import dayjs from 'dayjs';

const Subscription = ({
  handleSubsciption,
  formData,
  handleInputChange,
  setFormData,
}) => {
  // Data
  const subscriptionData = useSelector((state) => state?.sub?.response?.data);

  const StyledLabel = styled('div')(({ theme, checked }) => ({
    margin: '20px 0',
    padding: '0px 16px',
    border: `1px solid ${checked ? theme.palette.primary.main : 'gray'}`,
    borderLeft: `${
      checked ? `10px solid ${theme.palette.primary.main}` : '1px solid gray'
    }`,
    backgroundColor: checked ? theme.palette.primary.lightPurple : 'white',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
  }));

  const handleDateChange = (key, date) => {
    setFormData((prev) => ({
      ...prev,
      subscription: {
        ...prev.subscription,
        [key]: dayjs(date).format('YYYY-MM-DD'),
      },
    }));
  };
  

  return (
    <>
      <Box width={'100%'}>
        <Grid container spacing={2}>
          <Grid item xxs={12} xl={6}>
            <Box bgcolor={'white'} borderRadius={'5px'} minHeight={'312px'}>
              <FormGroup sx={{ width: '100%' }}>
                <RadioGroup sx={{ width: '100%' }}>
                  <Box px={'20px'} pb={'40px'} pt={'25px'}>
                    <Typography
                      component='p'
                      fontSize={'18px'}
                      color='primary.gray'
                      marginBottom={'15px'}
                    >
                      Subscription
                    </Typography>
                    {subscriptionData?.map(
                      ({
                        id,
                        created_at,
                        name,
                        price_per_license,
                        updated_at,
                        user_limit,
                        uuid,
                      }) => (
                        <StyledLabel
                          key={id}
                          value={name}
                          control={<Radio sx={{ display: 'none' }} />}
                          checked={
                            formData?.subscription?.subscription_id === uuid
                          }
                          onClick={() =>
                            handleSubsciption({
                              billing_start_date: created_at.split('T')[0],
                              price_per_license: price_per_license,
                              setup_fee_start_date: created_at.split('T')[0],
                              subscription_id: uuid,
                              name: name,
                              user_limit: user_limit,
                              setup_fee: '',
                            })
                          }
                        >
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                          >
                            <Typography
                              component='p'
                              fontSize={'14px'}
                              color='primary.gray'
                              marginBottom={'15px'}
                              mt={'20px'}
                            >
                              {name}
                            </Typography>
                            <Typography
                              component='p'
                              fontSize={'14px'}
                              color={'primary.gray'}
                              marginBottom={'15px'}
                              fontWeight={500}
                              mt={'20px'}
                            >
                              {`$${price_per_license} / Month`}
                            </Typography>
                          </Box>
                        </StyledLabel>
                      )
                    )}
                  </Box>
                </RadioGroup>
              </FormGroup>
            </Box>
          </Grid>

          <Grid item xxs={12} xl={6}>
            <Box bgcolor={'white'} borderRadius={'5px'}>
              <FormGroup sx={{ width: '100%' }}>
                <RadioGroup sx={{ width: '100%' }}>
                  <Box px={'20px'} pb={'40px'} pt={'25px'}>
                    <Typography
                      component='p'
                      fontSize={'18px'}
                      color='primary.gray'
                      marginBottom={'15px'}
                    >
                      Subscription Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xxs={12} sm={6}>
                        <TextInput
                          name='name'
                          label='Name'
                          placeholder={'Name'}
                          disabled={true}
                          type='text'
                          onChange={handleInputChange('subscription')}
                          value={formData?.subscription?.name || ''}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xxs={12} sm={6}>
                        <TextInput
                          name='user_limit'
                          label='User Limit'
                          placeholder='User Limit'
                          disabled={true}
                          type='text'
                          onChange={handleInputChange('subscription')}
                          value={formData?.subscription?.user_limit || ''}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xxs={12} sm={6}>
                        <TextInput
                          name='Price per license'
                          label='Price per license'
                          placeholder='Price per license'
                          disabled={true}
                          type='text'
                          onChange={handleInputChange('subscription')}
                          value={formData.subscription.price_per_license || ''}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xxs={12} sm={6}>
                        <CustomDatePicker
                          label='Recurring Billing Start Date'
                          value={
                            formData?.subscription?.billing_start_date || ''
                          }
                          name='billing_start_date'
                          handleOnChange={handleDateChange}
                        />
                      </Grid>

                      <Grid item xxs={12} sm={6}>
                        <TextInput
                          name='setup_fee'
                          label='Setup Fee'
                          placeholder='Setup Fee'
                          type='number'
                          onChange={handleInputChange('subscription')}
                          value={formData?.subscription?.setup_fee || ''}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'white',
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xxs={12} sm={6}>
                        <CustomDatePicker
                          label='Setup Fee Billing Start Date'
                          value={
                            formData?.subscription?.setup_fee_start_date || ''
                          }
                          name='setup_fee_start_date'
                          handleOnChange={handleDateChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </RadioGroup>
              </FormGroup>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Subscription;
