// Library Imports
import React, { useState, useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import { Box } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useSearchParams } from 'react-router-dom';

// Local Imports
import TableMainBody from './TableBody';
import TableHeader from './TableHeader';

export const CustomTable = ({
  rows,
  columns,
  tableColumnExtensions,
  dataProviders,
  onRowClick,
  isUnits,
  open,
  isSelectedCampus,
  metaData,
}) => {
  // REFS
  const tableRef = useRef(null);
  // STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIds, setSelectedIds] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [page, setPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page') - 1) : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(searchParams.get('pageSize')) || 20
  );
  const handleChangePage = (event, newPage) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('page', newPage + 1);
      return params;
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const pageOfTotalRecords = Math.ceil(
      metaData?.total_record / event.target.value
    );
    const currentPage = parseInt(searchParams.get('page')) || 1;
    if (currentPage <= pageOfTotalRecords) {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set('pageSize', event.target.value);
        return params;
      });
      setRowsPerPage(event.target.value);
    }
  };

  const rowsToShow =
    rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [];

  // ONSCROLL
  const debouncedSetScrollPosition = debounce((scrollTop) => {
    setScrollPosition(scrollTop);
  }, 200);

  // ONSCROLL
  const onScroll = (event) => {
    debouncedSetScrollPosition(event.target.scrollTop);
  };
  useEffect(() => {
    const storedScrollPosition = JSON.parse(
      localStorage.getItem('scrollPosition')
    );
    if (storedScrollPosition !== null && !open) {
      setScrollPosition(storedScrollPosition);
      if (tableRef.current) {
        tableRef.current.scrollTop = storedScrollPosition;
      }
    }
  }, [open]);

  // Store scroll position in local storage
  useEffect(() => {
    localStorage.setItem('scrollPosition', JSON.stringify(scrollPosition));
  }, [scrollPosition]);

// debounce; 
  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  return (
    <React.Fragment>
      <Box
        component={'div'}
        display={'flex'}
        flexDirection={'column'}
        color={'primary.gray'}
        height={'100%'}
        overflow={'hidden'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: 'calc(100% - 42px)',
            overflow: 'auto',
            borderRadius: 0,
          }}
          ref={tableRef}
          onScroll={(event) => onScroll(event)}
        >
          <Table
            sx={{ minWidth: 650, tableLayout: 'fixed' }}
            size='small'
            aria-label='a dense table'
          >
            <TableHeader
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
            {/* TABLE MAIN BODY ROWS */}
            <TableMainBody
              rows={metaData !== '' ? rows : rowsToShow}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              dataProviders={dataProviders}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              onRowClick={onRowClick}
              isUnits={isUnits}
            />
          </Table>
        </TableContainer>

        <TablePagination
          component='div'
          sx={{
            color: '',
            height: '40px',
            overflow: 'hidden',
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular': {
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 0,
              paddingBottom: 3,
              // pt: '7px',
              paddingLeft: '13px',
              borderTop: '0px !important',
              backgroundColor: 'primary.cloudWhite',
              color: 'primary.darkGray',
            },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:first-of-type':
              {
                display: 'none',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > div:nth-of-type(3)':
              {
                fontSize: '15px',
                marginLeft: '15px',
              },
            '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular > p:nth-of-type(2)':
              {
                fontSize: '12px',
                marginLeft: 'auto',
              },
            '& .MuiTablePagination-actions button > svg': {
              fontSize: '20px',
            },
            '& .MuiSvgIcon-root': {
              // backgroundColor: gray,
              fill: 'primary.darkGray',
            },
          }}
          count={metaData?.total_record || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 50, 100]}
        />
      </Box>
    </React.Fragment>
  );
};
