// Library imports
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';

export const CustomAvatar = ({
  sideMenu,
  userName,
  role,
  reverse,
  avatarSX,
  userNameSx,
  url,
}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={reverse ? 'row-reverse' : 'row'}
      gap={1}
      justifyContent='start'
      alignItems='center'
    >
      <Box
        display={{ xxs: sideMenu ? 'flex' : 'none', sm: 'flex' }}
        flexDirection='column'
      >
        <Typography
          fontSize='16px'
          component={'p'}
          fontFamily={'Montserrat'}
          sx={{ ...userNameSx }}
        >
          {userName}
        </Typography>
        <Typography
          mt={'-2px'}
          fontSize={'10px'}
          fontWeight={400}
          variant='base'
          color='primary.darkGray'
          fontFamily={'Montserrat'}
        >
          {role}
        </Typography>
      </Box>

      <Avatar
        alt={userName}
        src={url}
        sx={{
          width: 36,
          height: 36,
          bgcolor: 'secondary.main',
          ...avatarSX,
        }}
      >
        {/* If no URL is provided, fallback to displaying the user's initial */}
        {!url && (
          <Typography
            variant='h6'
            sx={{
              fontSize: '14px',
              padding: 0,
              lineHeight: 0,
            }}
          >
            {userName?.charAt(0).toUpperCase()}
          </Typography>
        )}
      </Avatar>
    </Box>
  );
};
