// Library imports
import React from 'react';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CustomSnackbar = ({ error, success, message, open, handleClose }) => {
  return (
    <Snackbar
      sx={{
        '& .MuiSnackbarContent-root': {
          bgcolor: 'white',
          color: 'black',
          fontSize: '14px',
          borderLeft: '5px solid',
          borderColor: error
            ? 'primary.danger'
            : success
            ? 'primary.success'
            : 'primary.main',
          fontWeight: 500,
        },
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={50}
      onClose={handleClose}
      message={message}
      action={
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={(event) => handleClose(event, 'closeButtonClick')}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />
  );
};
