import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';

export const CustomDatePicker = ({ label, value, name, handleOnChange }) => {
  return (
    <Box mt={2.1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value ? dayjs(value) : null}
          onChange={(newValue) => handleOnChange(name, newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              sx: {
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white',
                  width: '100%',
                  height: '40px', 
                  borderRadius: '4px',
                  boxShadow: 'none',
                  border: '1px solid #ccc',
                },
                '& .MuiInputBase-input': {
                  padding: '8px 14px',
                  fontSize: '16px', 
                  color: '#333', 
                },
                '& .MuiFormLabel-root': {
                  color: '#888', 
                  fontSize: '14px', 
                  marginTop: '-5px',
                },
                '& .MuiSvgIcon-root': {
                  color: '#666', 
                  fontSize: '20px', 
                  marginRight: '8px', 
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ddd',
                },
                '& .MuiInputBase-root': {
                  padding: '0 !important', 
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
