import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CustomButton,
  TextInput,
  CustomDivider,
  CustomCheckbox,
} from "../../../components/shared";
import { permissionsListing } from "../../../redux/reducers/rolesAndPermissions/prmissionsList";
import { createOrUpdateRole } from "../../../redux/reducers/rolesAndPermissions/addUpdateRole";

const AddRole = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.permissionListing);
  const [errorsShow, setErrorsShow] = useState(false);
  console.log("errorshow>>>>>>>>>>>>>>>>>>>>>>>>>", errorsShow);
  useEffect(() => {
    dispatch(permissionsListing());
  }, [dispatch]);

  useEffect(() => {
    if (errorsShow.success) {
      navigate("/roles-and-permissions");
    }
  }, [errorsShow]);
  const [formData, setFormData] = useState({
    uuid: "",
    name: "",
    permission_ids: [],
  });

  const navigate = useNavigate();

  const handleCheckbox = (event, parentId, childId, isSelectAll = false) => {
    const { checked } = event.target;

    setFormData((prev) => {
      let updatedPermissions = [...(prev.permission_ids || [])];

      if (isSelectAll) {
        // Handle Select All
        const childPermissions = store?.response?.payload?.data
          ?.find(({ uuid }) => uuid === parentId)
          ?.children.map((child) => child.uuid);

        updatedPermissions = checked
          ? [...new Set([...updatedPermissions, ...childPermissions])]
          : updatedPermissions.filter((id) => !childPermissions.includes(id));
      } else {
        // Handle individual permissions
        updatedPermissions = checked
          ? [...updatedPermissions, childId]
          : updatedPermissions.filter((id) => id !== childId);
      }

      return {
        ...prev,
        uuid: parentId,
        permission_ids: updatedPermissions,
      };
    });
  };

  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted FormData:", formData);

    try {
      const resultAction = await dispatch(
        createOrUpdateRole({
          name: formData?.name,
          permission_ids: formData?.permission_ids,
        })
      );

      setErrorsShow({
        isError: resultAction?.error ? true : false,
        message: resultAction?.payload?.message
          ? resultAction?.payload?.message
          : resultAction?.payload,
      });

      if (createOrUpdateRole.fulfilled.match(resultAction)) {
        console.log("Role operation successful:", resultAction.payload);
        // Add success logic here (e.g., redirect or show a message)
      } else {
        console.error("Role operation failed:", resultAction.payload);
        // Add error-handling logic here (e.g., show an error message)
      }
    } catch (error) {
      console.error("Unexpected error during role operation:", error);
    }
  };

  const isPermissionChecked = (childId) =>
    formData.permission_ids.includes(childId);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ height: "calc(100vh - 63px)" }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Box
        backgroundColor="white"
        borderRadius="5px"
        mx="20px"
        overflow="auto"
        maxHeight="565px"
        mt="25px"
      >
        <Box mx="20px" mb="15px" p="20px 0px">
          <Typography
            variant="h4"
            color="primary.darkGray"
            fontSize="18px"
            fontWeight={500}
          >
            Add Role
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={{ xxs: 0, xm: 1 }}
            py="20px"
          >
            <Grid container spacing={{ xm: 2 }}>
              <Grid item xxs={12} sm={9} base={5}>
                <TextInput
                  name="name"
                  placeholder="Enter Role Name"
                  type="text"
                  onChange={handleInput}
                  value={formData.name}
                />
              </Grid>
            </Grid>

            <Typography
              component="p"
              fontSize="14px"
              color="primary.gray"
              mt="20px"
              fontWeight={500}
              mb={{ xxs: "10px", xm: 0 }}
            >
              Select Permissions For Role
            </Typography>

            {store?.response?.payload?.data?.map(
              ({ uuid: parentId, name, children }, index) => (
                <Box key={index} width="100%">
                  <Grid container spacing={{ xm: 1 }}>
                    <Grid item xxs={12} sm={3} base={2}>
                      <Typography
                        component="p"
                        fontSize="12px"
                        color="primary.gray"
                        marginBottom={{ sx: "15px" }}
                        marginTop="10px"
                        fontWeight={500}
                      >
                        {name}
                      </Typography>
                    </Grid>

                    <Grid item xxs={12} sm={9} base={8}>
                      <Box display="flex" flexWrap="wrap" gap={2}>
                        {name === "Administrator Access" && (
                          <CustomCheckbox
                            checked={children.every((child) =>
                              isPermissionChecked(child.uuid)
                            )}
                            onChange={(e) =>
                              handleCheckbox(e, parentId, null, true)
                            }
                            label="Select All"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                              },
                            }}
                          />
                        )}

                        {children.map(({ uuid: childId, name }) => (
                          <CustomCheckbox
                            key={childId}
                            checked={isPermissionChecked(childId)}
                            onChange={(e) =>
                              handleCheckbox(e, parentId, childId)
                            }
                            label={name}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "12px",
                              },
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <CustomDivider />
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>

      <Box
        bgcolor="white"
        height="62px"
        display="flex"
        justifyContent="end"
        alignItems="center"
        px="20px"
        py="10px"
        gap={2}
      >
        <CustomButton
          onClick={() => navigate("/roles-and-permissions")}
          variant="outlined"
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          sx={{ width: { xxs: "100%", xs: "200px" } }}
        >
          Create Role
        </CustomButton>
      </Box>
    </Box>
  );
};

export default AddRole;
