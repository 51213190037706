// Library imports 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports 
import axiosInstance from '../../network/api/http';
import endPoints from '../../network/api/constant';

// Initial State
const initialState = { currentUser: null, loading: false, error: null }

// Login Api 
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endPoints.login, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);

      // Handle both response errors and network errors
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.currentUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
