// Library imports
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Local imports
import { CustomCheckbox } from '../../shared';

const Cell = styled(TableCell)(({ theme }) => ({
  color: 'orimary.darkGray',
  backgroundColor: 'transparent',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  // padding: '10px',
  height: '40px',
  padding: '0px 10px',
  minWidth: 0,
}));

const SelectionCell = styled(TableCell)(({ theme }) => ({
  color: 'primary.gray',
  backgroundColor: 'transparent',
  fontFamily: 'Montserrat',
  fontSize: '12px',
  borderBottom: 'none',
  // padding: '19.3px 0px',
  height: '40px',
  borderBottom: `1px solid ${'primary.gray'}`,
  padding: '0px 10px',
  minWidth: 0,
}));

const Row = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  borderBottom: `1px solid ${'primary.gray'}`,
  height: '45px',
}));

const TableHeader = ({
  rows,
  columns,
  selectedIds,
  setSelectedIds,
  tableColumnExtensions,
}) => {
  return (
    <React.Fragment>
      <TableHead
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'primary.cloudWhite',
          color: 'primary.darkGray',
          zIndex: 1,
        }}
      >
        <Row>
          {selectedIds?.length > 0 ? (
            <SelectionCell width={'30px'}>
              <Box
                sx={{
                  backgroundColor: 'primary.cloudWhite',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '40px',
                  p: 0,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'primary.cloudWhite',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // pl: '11px',
                    // px: 2,
                    // padding: 0,
                  }}
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedIds([]);
                    }}
                    sx={{
                      width: 15,
                      height: 15,
                      borderRadius: '3px',
                      border: '2px solid #7367F0',
                      textAlign: 'center',
                      backgroundColor: 'primary.main',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 0,
                    }}
                  >
                    <Box
                      sx={{
                        color: 'white',
                        fontSize: '24px',
                        pb: '2px',
                      }}
                    >
                      -
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      fontSize: 13,
                      ml: '5px',
                      color: 'primary.darkGray',
                      backgroundColor: 'primary.cloudWhite',
                      // p: 1,
                      pr: 2,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {selectedIds?.length} Selected
                  </Box>
                </Box>
              </Box>
            </SelectionCell>
          ) : (
            <Cell width={'30px'}>
              <CustomCheckbox
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'primary.lightGray',
                  },
                }}
                checked={Boolean(selectedIds?.length > 0 ? true : false)}
                
                onChange={() => {
                  if (selectedIds?.length === 0) {
                    setSelectedIds(() => rows?.map((row) => row?.id));
                  } else {
                    setSelectedIds([]);
                  }
                }}
              />
            </Cell>
          )}

          {columns?.map((col, colIndex) => {
            // GET COLUMN EXTENSION
            const columnExtension = tableColumnExtensions?.find(
              (item) => item?.columnName === col?.name
            );
            const width = columnExtension?.width || 100;
            return (
              <Cell
                key={colIndex}
                color={'primary.gray'}
                width={width || 100}
                style={{
                  minWidth: width,
                  userSelect: 'none',
                  textAlign: col?.name === 'status' ? 'center' : 'left',
                }}
              >
                {selectedIds?.length === 0 ? col?.title : ''}
              </Cell>
            );
          })}
        </Row>
      </TableHead>
    </React.Fragment>
  );
};

export default TableHeader;
