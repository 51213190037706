// Library imports
import React from "react";
import { Box, Typography } from "@mui/material";

export const ComingSoon = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "calc(100vh - 100px)",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", mb: 2, color: "primary.gray" }}
      >
        Feature Coming Soon!
      </Typography>
    </Box>
  );
};
