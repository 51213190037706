// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports
import axiosInstance from '../../network/api/http';
import endPoints from '../../network/api/constant';

// Initial State
const initialState = {
  response: null,
  loading: false,
  error: null,
};
console.log('initialState', initialState);

// Unified Update User API
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ uuid, firstName, lastName, email, role_ids }, { rejectWithValue }) => {
    try {
      const payload = {
        uuid,
        firstName,
        lastName,
        email,
        role_ids,
      };

      const response = await axiosInstance.put(endPoints.userStore, payload); // Use PUT method for update
      return response.data;
    } catch (error) {
      console.error('User update operation failed:', error);
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);

// Slice for managing user updates
const updateUserSlice = createSlice({
  name: 'updateUser', 
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update user';
      });
  },
});

export default updateUserSlice.reducer;
