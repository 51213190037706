import * as React from "react";

export const AnalyticsIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 10 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.416016 4.99935C0.416016 2.45768 2.45768 0.416016 4.99935 0.416016C7.54101 0.416016 9.58268 2.45768 9.58268 4.99935C9.58268 7.54101 7.54101 9.58268 4.99935 9.58268C2.45768 9.58268 0.416016 7.54101 0.416016 4.99935ZM1.24935 4.99935C1.24935 7.08268 2.91602 8.74935 4.99935 8.74935C7.08268 8.74935 8.74935 7.08268 8.74935 4.99935C8.74935 2.91602 7.08268 1.24935 4.99935 1.24935C2.91602 1.24935 1.24935 2.91602 1.24935 4.99935Z"
      fill="currentColor"
    />
    <mask
      id="mask0_238_25387"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={10}
      height={10}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.416016 4.99935C0.416016 2.45768 2.45768 0.416016 4.99935 0.416016C7.54101 0.416016 9.58268 2.45768 9.58268 4.99935C9.58268 7.54101 7.54101 9.58268 4.99935 9.58268C2.45768 9.58268 0.416016 7.54101 0.416016 4.99935ZM1.24935 4.99935C1.24935 7.08268 2.91602 8.74935 4.99935 8.74935C7.08268 8.74935 8.74935 7.08268 8.74935 4.99935C8.74935 2.91602 7.08268 1.24935 4.99935 1.24935C2.91602 1.24935 1.24935 2.91602 1.24935 4.99935Z"
        fill="current"
      />
    </mask>
    <g mask="url(#mask0_238_25387)">
      <rect width={10} height={10} fill="white" />
    </g>
  </svg>
);
