import * as React from "react";

export const DirectMessagesIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 1.5H14.25C15.525 1.5 16.5 2.475 16.5 3.75V11.25C16.5 12.525 15.525 13.5 14.25 13.5H5.55L2.775 16.275C2.625 16.425 2.475 16.5 2.25 16.5C2.175 16.5 2.025 16.5 1.95 16.425C1.65 16.35 1.5 16.05 1.5 15.75V3.75C1.5 2.475 2.475 1.5 3.75 1.5ZM14.25 12C14.7 12 15 11.7 15 11.25V3.75C15 3.3 14.7 3 14.25 3H3.75C3.3 3 3 3.3 3 3.75V13.95L4.725 12.225C4.875 12.075 5.025 12 5.25 12H14.25Z"
      fill="black"
    />
    <mask
      id="mask0_225_13340"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={1}
      width={16}
      height={16}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1.5H14.25C15.525 1.5 16.5 2.475 16.5 3.75V11.25C16.5 12.525 15.525 13.5 14.25 13.5H5.55L2.775 16.275C2.625 16.425 2.475 16.5 2.25 16.5C2.175 16.5 2.025 16.5 1.95 16.425C1.65 16.35 1.5 16.05 1.5 15.75V3.75C1.5 2.475 2.475 1.5 3.75 1.5ZM14.25 12C14.7 12 15 11.7 15 11.25V3.75C15 3.3 14.7 3 14.25 3H3.75C3.3 3 3 3.3 3 3.75V13.95L4.725 12.225C4.875 12.075 5.025 12 5.25 12H14.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_225_13340)">
      <rect width={18} height={18} fill="currentColor" />
    </g>
  </svg>
);
