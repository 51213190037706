import * as React from "react";

export const FundraisingIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.56579 5.01598C6.53511 5.01598 8.13157 4.21775 8.13157 3.23309C8.13157 2.24842 6.53511 1.4502 4.56579 1.4502C2.59646 1.4502 1 2.24842 1 3.23309C1 4.21775 2.59646 5.01598 4.56579 5.01598Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 3.2334V5.72945C1 6.71396 2.5964 7.51234 4.56579 7.51234C6.53517 7.51234 8.13157 6.71396 8.13157 5.72945V3.2334"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 5.72949V8.22554C1 9.21006 2.5964 10.0084 4.56579 10.0084C6.53517 10.0084 8.13157 9.21006 8.13157 8.22554V5.72949"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8.22559V10.7216C1 11.7061 2.5964 12.5045 4.56579 12.5045C6.53517 12.5045 8.13157 11.7061 8.13157 10.7216V8.22559"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 10.7217V13.2177C1 14.2022 2.5964 15.0006 4.56579 15.0006C6.53517 15.0006 8.13157 14.2022 8.13157 13.2177V10.7217"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6971 10.0082C13.6665 10.0082 15.2629 9.20994 15.2629 8.22528C15.2629 7.24061 13.6665 6.44238 11.6971 6.44238C9.7278 6.44238 8.13135 7.24061 8.13135 8.22528C8.13135 9.20994 9.7278 10.0082 11.6971 10.0082Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.13135 8.22559V10.7216C8.13135 11.7061 9.72775 12.5045 11.6971 12.5045C13.6665 12.5045 15.2629 11.7061 15.2629 10.7216V8.22559"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.13135 10.7217V13.2177C8.13135 14.2022 9.72775 15.0006 11.6971 15.0006C13.6665 15.0006 15.2629 14.2022 15.2629 13.2177V10.7217"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
