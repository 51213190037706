import React from 'react';
import Button from '@mui/material/Button';
import { Spinner } from '../Spinner';

export const CustomButton = ({ variant, onClick, disabled, loading, children, sx,type }) => {
  return (
    <Button
      variant={variant}
      onClick={disabled || loading ? null : onClick}
      disabled={disabled}
      type={type || "submit"}
      sx={{
        textTransform: 'none',
        height: '39px',
        display: 'flex !important',
        alignItems: 'center',
           boxShadow: 'none', 
        '&:hover': {
          boxShadow: 'none', 
        },
        '&:disabled': {
          color: 'white',
          backgroundColor: 'primary.main',
          opacity: '0.5',
        },
        ...sx,
      }}
    >
      {loading ? <Spinner sx={{color:'white'}}/> : children}
    </Button >
  );
};
