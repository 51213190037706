// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = {
  response: null,
  loading: false,
  error: null,
};

// Delete User API
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `${endPoints.deleteUser}?uuid[]=${uuid}`
      );
      return response.data;
    } catch (error) {
      console.error("User deletion failed:", error);
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

// Slice for managing users
const deleteUserSlice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Delete User
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete user";
      });
  },
});

export default deleteUserSlice.reducer;
