// Base URL
export const baseURL = "https://camphq-api.testerp.co";

// Endpoints
const endPoints = {
  /*********************** User Management *************************/

  // Login User
  login: `${baseURL}/api/login`,

  // Forgot Password
  forgotPassword: `${baseURL}/api/forgot-password`,

  // Reset Password
  resetPassword: `${baseURL}/api/reset-password`,

  /*********************** Organization Management *************************/

  // Add Organization
  addOrganization: `${baseURL}/api/organization/store`,

  // Update Organization
  updateOrganization: `${baseURL}/api/organization/update-status`,

  // Organization Listing
  organizationListing: `${baseURL}/api/organization/list`,

  /*********************** Subscription Management *************************/

  // Subscription Listing
  subscriptionsListing: `${baseURL}/api/subscriptions`,

  /*********************** Role Management *************************/

  // Role Listing
  roleListing: `${baseURL}/tenant/api/role/list`,

  // Role Permissions Listing
  permissionsListing: `${baseURL}/tenant/api/permission/list`,

  // Create Role
  roleStore: `${baseURL}/tenant/api/role/store`,

  // Delete Role (Soft Delete)
  deleteRole: `${baseURL}/tenant/api/role/delete/`,

  /*********************** Position Board *************************/

  // Position Board Listing
  positionBoardList: `${baseURL}/tenant/api/position-board/list`,

  /*********************** User Management *************************/

  // User Listing
  usersListing: `${baseURL}/tenant/api/user/list`,

  // Delete User (Soft Delete)
  deleteUser: `${baseURL}/tenant/api/user/delete`,

  // Update User
  updateUser: `${baseURL}/tenant/api/user/update`,

  // Store New User
  storeUser: `${baseURL}/tenant/api/user/store`,

  // position board listing  
  positionOnBoard:`${baseURL}/tenant/api/position-board/list`,
};

export default endPoints;
