// Library Imports
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

// Local Imports
import { privateRoutes, publicRoutes, routes } from './config';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useSelector } from 'react-redux';
export const Router = () => {
  const user = useSelector((state) => state?.auth?.currentUser?.data);
  const currentRoutes = routes[`${user?.role}`];

  return (
    <Fragment>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {currentRoutes?.map((route, index) => {
            return route.child ? (
              route.child.map((childRoute, index) => (
                <Fragment key={index}>
                  <Route path={route.path} element={route.element} />
                  <Route path={childRoute.path} element={childRoute.element} />
                </Fragment>
              ))
            ) : (
              <Route path={route.path} element={route.element} key={index} />
            );
          })}
        </Route>
        <Route element={<PublicRoutes />}>
          {publicRoutes?.map((route, index) => {
            return (
              <Route path={route?.path} element={route?.element} key={index} />
            );
          })}
        </Route>
      </Routes>
    </Fragment>
  );
};
