// Library Imports
import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// Icons Imports
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";

// Local Imports
import { routes, sideMenuRoutes } from "../../../routes/config";
import { assets } from "../../../assets/Images";
import { roles } from "../../../utils/data";
import { CustomPopover } from "../CustomPopover";
import { CustomAvatar } from "../CustomAvatar";
import { PopoverData } from "../PopoverData";
import { logoutUser } from "../../../redux/reducers/auth/authReducers";

export const PanelLayout = ({ children }) => {
  const user = useSelector((state) => state?.auth?.currentUser?.data);
  const currentRoutes = routes[`${user?.role}`];
  const isLargeScreen = useMediaQuery("(min-width:992px)");
  const [sideMenu, setSideMenu] = useState(isLargeScreen);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location?.pathname;
  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const currentPathName = sideMenuRoutes?.find(
    ({ moduleName }) =>
      moduleName?.toLowerCase() ===
      currentPath?.split("/")[1]?.split("-")?.join(" ")
  );

  const popoverData = [
    {
      icon: <ManageAccountsIcon fontSize="medium" />,
      label: { text: "Profile" },
    },
    {
      icon: <LogoutIcon fontSize="small" />,
      label: { text: "Logout" },
      action: handleLogout,
    },
  ];

  useEffect(() => {
    setSideMenu(isLargeScreen);
  }, [isLargeScreen]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      width={"100%"}
      height={"100vh"}
      position={"relative"}
      overflow={"hidden"}
    >
      {/* Side menu  */}
      <Box
        height={"100%"}
        alignItems={!sideMenu && "center"}
        width={sideMenu ? { xxs: "250px", base: "270px" } : "60px"}
        sx={{
          transition: "width 0.4s",
        }}
        position={{ xxs: "absolute", base: "relative" }}
        zIndex={999}
        top={"0px"}
        bottom={"0px"}
        left={"0px"}
        bgcolor={"white"}
        boxShadow={{
          xxs: sideMenu && "1px 0px 12px 1px rgba(150, 150, 150, 0.3)",
          base: "none",
        }}
        borderRight={"1px solid rgba(161, 164, 172, 0.5)"}
      >
        {/*Side menu Header */}
        <Box
          pt={"22px"}
          pb={"25px"}
          display={"flex"}
          justifyContent={sideMenu ? "space-between" : "center"}
          alignItems={"center"}
          mx={sideMenu ? "8px" : "0px"}
          position={"relative"}
          my={{ xxs: "auto", base: "20px" }}
        >
          <Box
            display={"flex"}
            width={{ xxs: "100px", base: "120px" }}
            position={"absolute"}
            left={!sideMenu ? "-300px" : "0"}
            sx={{
              transition: "0.4s",
            }}
            ml={sideMenu ? "6px" : "0px"}
          >
            <img
              src={assets?.logo}
              alt="Logo"
              loading="lazy"
              width={"100%"}
              height={"100%"}
            />
          </Box>
          <Box
            color={"primary.main"}
            sx={{ cursor: "pointer" }}
            display={{ xxs: "block", base: "none" }}
            ml={sideMenu ? "auto" : "0px"}
          >
            {sideMenu ? (
              <KeyboardDoubleArrowLeftIcon
                onClick={() => setSideMenu(false)}
                sx={{
                  height: "26px",
                  width: "26px",
                }}
              />
            ) : (
              <MenuIcon
                onClick={() => setSideMenu(true)}
                sx={{
                  color: "primary.main",
                  height: "26px",
                  width: "26px",
                }}
              />
            )}
          </Box>
        </Box>

        {/*Side menu body */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          style={{ height: "calc(100vh - 95px)" }}
          gap={"8px"}
          pr={sideMenu ? "8px" : "12px"}
          pl={"12px"}
          sx={{ userSelect: "none" }}
          overflow={"auto"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
            {currentRoutes.map(({ path, moduleName, icon }, index) => {
              return (
                <Box
                  key={index}
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  justifyContent={sideMenu ? "start" : "center"}
                  px={"7px"}
                  // mx={!sideMenu ? '7px' : '0px'}
                  bgcolor={
                    currentPath?.split("/")[1]?.split("-")?.join(" ") ===
                      moduleName?.toLowerCase() && "primary.main"
                  }
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        currentPath?.split("/")[1]?.split("-")?.join(" ") !==
                          moduleName?.toLowerCase() && "primary.halfWhite",
                    },
                  }}
                  py={"5px"}
                  onClick={() => navigate(path)}
                  borderRadius={"4px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    color={
                      currentPath?.split("/")[1]?.split("-")?.join(" ") ===
                      moduleName?.toLowerCase()
                        ? "white"
                        : "primary.main"
                    }
                    // pl={sideMenu ? '' : '0px'}
                  >
                    {icon}
                  </Box>
                  {sideMenu ? (
                    <Typography
                      component={"p"}
                      whiteSpace={"nowrap"}
                      fontSize={"14px"}
                      fontWeight={500}
                      color={
                        currentPath?.split("/")[1]?.split("-")?.join(" ") ===
                        moduleName?.toLowerCase()
                          ? "white"
                          : "black"
                      }
                      lineHeight={"12px"}
                      sx={{
                        "&:hover": {
                          color:
                            currentPath
                              ?.split("/")[1]
                              ?.split("-")
                              ?.join(" ") !== moduleName?.toLowerCase() &&
                            "black",
                        },
                      }}
                    >
                      {moduleName}
                    </Typography>
                  ) : null}
                </Box>
              );
            })}
          </Box>

          <Box
            display={{ xxs: "flex", sm: "none" }}
            flexDirection={"row-reverse"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
            alignSelf={sideMenu && "flex-start"}
          >
            <CustomPopover
              trigger={
                <CustomAvatar
                  sideMenu={sideMenu}
                  userName={user?.username}
                  role={roles[user?.role]}
                  reverse
                />
              }
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Box pl={1} pr={3} py={1} p={"12px"} width={"127px"}>
                {popoverData.map(({ icon, label }, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    flexDirection={"column"}
                    py={"3px"}
                  >
                    <PopoverData icon={icon} label={label} />
                  </Box>
                ))}
              </Box>
            </CustomPopover>
          </Box>
        </Box>
      </Box>

      {/* Children  */}
      <Box
        width={sideMenu ? { xxs: "100%", base: "calc(100vw - 270px)" } : "100%"}
        overflow={"hidden"}
        height={"100%"}
        bgcolor={"primary.cloudGray"}
        ml={{ xxs: "60px", base: "0px" }}
        position={"relative"}
      >
        <Box
          bgcolor={"white"}
          height={"62px"}
          // borderBottom={'0.5px solid rgba(161, 164, 172, 0.5)'}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={{ xxs: "10px", base: "20px" }}
        >
          <Typography
            fontSize={{ xxs: "18px", md: "22px" }}
            fontWeight={500}
            color="primary.gray"
          >
            {currentPathName?.moduleName}
          </Typography>

          <Box
            display={{ xxs: "none", sm: "flex" }}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CustomPopover
              trigger={
                <CustomAvatar
                  userName={user?.username}
                  role={roles[user?.role]}
                />
              }
            >
              <Box p={"12px"} width={"127px"}>
                {popoverData.map(({ icon, label, action }, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    flexDirection={"column"}
                    py={"4px"}
                  >
                    <PopoverData icon={icon} label={label} onClick={action} />
                  </Box>
                ))}
              </Box>
            </CustomPopover>
          </Box>
        </Box>

        <Box>
          {children}
          {!isLargeScreen && sideMenu ? (
            <Box
              component={"div"}
              position={"absolute"}
              top={"0px"}
              left={"0px"}
              right={"0px"}
              height={"100%"}
              width={"100%"}
              bgcolor={"rgba(0, 0, 0, 0.3)"}
              onClick={() => setSideMenu(false)}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
