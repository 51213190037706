import React, { useState, useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";

// Local Imports
import { CustomButton } from "../Button";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.background?.paper,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  cardContainer: {
    width: "100px",
    height: "100px",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#DEDEDE",
    position: "relative",
  },
  cardRoot: {
    paddingBottom: "14px !important",
  },
  cardRootHide: {
    paddingBottom: "14px !important",
    margin: "-16px",
  },
  input: {
    display: "none",
  },
  logo: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
  },
}));

export const ImagePicker = ({ setFormData, isImage }) => {
  const classes = useStyles();
  const [uploadState, setUploadState] = useState("initial");
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isImage) {
      setImage(isImage);
      setUploadState("uploaded");
    }
  }, [isImage]);

  const handleUploadClick = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        setErrors({ logo: "File size should be less than 5MB" });
        return;
      } else if (
        !["image/jpeg", "image/png", "image/tiff", "image/webp"].includes(
          file.type
        )
      ) {
        setErrors({ logo: "Invalid file type. Only image files are allowed" });
        return;
      }

      setImage(file);
      setUploadState("uploaded");
      setFormData(file);
      setErrors({});
    }
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();
    setImage(null);
    setUploadState("initial");
    setFormData(null);
    setErrors({});
    document.getElementById("contained-button-file").value = "";
  };

  const handleUploadButtonClick = (event) => {
    event.stopPropagation();
    document.getElementById("contained-button-file").click();
  };

  const getImagePreview = () => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    return image;
  };

  return (
    <div className={classes.root}>
      <Card className={classes.cardContainer}>
        <CardContent
          className={
            uploadState !== "uploaded" ? classes.cardRoot : classes.cardRootHide
          }
        >
          <Grid container justify="center" alignItems="center">
            <input
              accept="image/jpeg,image/png,image/tiff,image/webp"
              className={classes.input}
              id="contained-button-file"
              name="avatar"
              type="file"
              onChange={handleUploadClick}
            />
            {uploadState !== "uploaded" && (
              <div
                style={{
                  backgroundColor: "#DEDEDE",
                  borderRadius: "50%",
                  // height: "60px",
                  // width: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AccountCircle sx={{ fontSize: "120px", color: "#7367F0" }} />
              </div>
            )}
          </Grid>
        </CardContent>

        {uploadState === "uploaded" && (
          <CardActionArea onClick={handleRemoveClick}>
            <img
              className={classes.logo}
              src={getImagePreview()}
              alt="Uploaded Logo"
            />
          </CardActionArea>
        )}
      </Card>

      <div>
        <CustomButton
          sx={{ width: { xxs: "100%", xs: "fit-content" }, height: "30px" }}
          onClick={
            uploadState === "uploaded"
              ? handleRemoveClick
              : handleUploadButtonClick
          }
          variant={uploadState === "uploaded" ? "outlined" : "contained"}
          type="button"
        >
          <Typography sx={{ fontSize: "12px" }}>
            {uploadState === "uploaded" ? "Remove Photo" : "Upload Image"}
          </Typography>
        </CustomButton>
        {errors.logo && <p style={{ color: "red" }}>{errors.logo}</p>}
      </div>
    </div>
  );
};
