// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = { response: null, loading: false, error: null };

// Position On Board listing
export const positionOnBoardListing = createAsyncThunk(
  "positionOnBoardListing",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(endPoints.positionOnBoard);

      return response?.data?.data;
    } catch (error) {
      console.error("Position On Board listing failed:", error);

      // Handle errors gracefully
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

const positionOnBoardListingSlice = createSlice({
  name: "positionOnBoardListing",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(positionOnBoardListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(positionOnBoardListing.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action;
      })
      .addCase(positionOnBoardListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch positions.";
      });
  },
});

export default positionOnBoardListingSlice.reducer;
