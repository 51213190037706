// Library Imports
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';

// Local import
import { TextInput } from '../../../../components/shared';

const AdminInformation = ({ handleInputChange, formData, isEdit }) => {
  return (
    <>
      <Box width={'100%'} backgroundColor='white' borderRadius='5px'>
        {/* Main container  */}
        <Box px={'20px'} pb={'40px'} pt={'25px'}>
          {/* Heading  */}
          <Typography
            component='p'
            fontSize={'18px'}
            color='primary.gray'
            marginBottom={'15px'}
          >
            Add Admin information
          </Typography>

          {/* Form  */}
          <Grid container spacing={2}>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='fname'
                label='First Name'
                placeholder='First Name'
                type='text'
                onChange={handleInputChange('admin')}
                value={formData?.admin?.fname}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='lname'
                label='Last Name'
                placeholder='Last Name'
                type='text'
                onChange={handleInputChange('admin')}
                value={formData?.admin?.lname}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name={'userName'}
                label='User Name'
                placeholder='User Name'
                type='text'
                onChange={handleInputChange('admin')}
                value={formData?.admin?.userName}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xxs={12} sm={6}>
              <TextInput
                name='email'
                label='Email'
                placeholder='John@gmail.com'
                type='email'
                onChange={handleInputChange('admin')}
                value={formData?.admin?.email}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xxs={12} sm={6}>
              <TextInput
                name='phone'
                label='Phone Number'
                placeholder='(123) 456-7890'
                type='tel'
                onChange={handleInputChange('admin')}
                value={formData?.admin?.phone}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AdminInformation;
