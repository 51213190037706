// Library imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Local imports
import axiosInstance from "../../network/api/http";
import endPoints from "../../network/api/constant";

// Initial State
const initialState = {
  response: null,
  loading: false,
  error: null,
};
console.log("initialState", initialState);
// Unified Create/Update Role API
export const createOrUpdateRole = createAsyncThunk(
  "roles/createOrUpdateRole",
  async ({ uuid, name, permission_ids }, { rejectWithValue }) => {
    try {
      const payload = {
        uuid: uuid || undefined,
        name,
        permission_ids,
      };

      const response = await axiosInstance.post(endPoints.roleStore, payload);
      return response.data;
    } catch (error) {
      console.error("Role operation failed:", error);
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

// Delete Role API
export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(endPoints.deleteRole + uuid);
      return response.data;
    } catch (error) {
      console.error("Role deletion failed:", error);
      if (error.response) {
        return rejectWithValue(error.response.data || "An error occurred");
      } else {
        return rejectWithValue("Network error: Please check your connection.");
      }
    }
  }
);

// Slice for managing roles
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrUpdateRole.fulfilled, (state, action) => {
        state.response = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(createOrUpdateRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create or update role";
      })
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete role";
      });
  },
});

export default roleSlice.reducer;
