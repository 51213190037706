// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports
import axiosInstance from '../../network/api/http';
import endPoints from '../../network/api/constant';

// Initial State
const initialState = { currentUser: null, loading: false, error: null };

// forgotPassword Api
export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async ({ email,url }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(endPoints.forgotPassword, {
        email,
        url
      });
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);

      // Handle both response errors and network errors
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export default forgotPasswordSlice.reducer;
