// Library imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Local imports
import authReducer from "./reducers/auth/authReducers";
import subsciptionListingSlice from "./reducers/organizations/subscriptionsListing";
import organizationListingSlice from "./reducers/organizations/organizationListing";
import forgotPasswordSlice from "./reducers/auth/forgotPassword";
import resetPasswordSlice from "./reducers/auth/resetPassword";
import addOrganizationSlice from "./reducers/organizations/addOrganizationReducer";
import updateOrganizationSlice from "./reducers/organizations/updateOrganization";
import permissionsListingSlice from "./reducers/rolesAndPermissions/prmissionsList";
import roleListingSlice from "./reducers/rolesAndPermissions/roleListing";
import roleSlice from "./reducers/rolesAndPermissions/roleListing";
import usersListingSlice from "./reducers/users/userListing";
import updateUserSlice from "./reducers/users/updateUser";
import deleteUserSlice from "./reducers/users/deleteUser";
import addUserSlice from "./reducers/users/addUser";
import positionOnBoardListingSlice from "./reducers/users/positionOnBoardListing";

// Define the persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  sub: subsciptionListingSlice,
  organizationListing: organizationListingSlice,
  forgetPassword: forgotPasswordSlice,
  resetPassword: resetPasswordSlice,
  addOrganization: addOrganizationSlice,
  updateOrganization: updateOrganizationSlice,
  permissionListing: permissionsListingSlice,
  roleListing: roleListingSlice,
  addUpdateRole: roleSlice,
  addUser: addUserSlice,
  updateUser: updateUserSlice,
  usersListing: usersListingSlice,
  positionOnBoardListing: positionOnBoardListingSlice,
  deleteUser:deleteUserSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Create a persistor
export const persistor = persistStore(store);
