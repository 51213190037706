// Library imports
import React  from 'react';
import Popover from '@mui/material/Popover';

// Local imports
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const CustomPopover = ({
  trigger,
  anchorOrigin,
  transformOrigin,
  children,
  sx,
  success
}) => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  return (
    <>
      <Box
        sx={{
          cursor: 'pointer',
          ...sx,
        }}
        width={'fit-content'}
        onClick={handleClick}
      >
        {trigger}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: 'bottom',
                horizontal: 'center',
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: 'top',
                horizontal: 'center',
              }
        }
      >
        {children}
      </Popover>
    </>
  );
};
