import { useSelector } from 'react-redux';
import { setAuthToken } from '../redux/network/api/http';
// Authentication Utils
export const useIsAuthenticatedUser = () => {
  const token = useSelector(
    (state) => state?.auth?.currentUser?.data?.access_token
  );
  if (token) {
    setAuthToken(token);
    return true;
  } else {
    return false;
  }
};
