// Library Imports
import React from 'react';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DescriptionIcon from '@mui/icons-material/Description';
import { Typography } from '@mui/material';

// Local Imports
import { CustomCheckbox } from '../../../components/shared';

const BodyCell = styled(TableCell)(({ theme }) => ({
  color: 'black',
  backgroundColor: 'transparent',
  fontFamily: 'Inter-Regular',
  fontSize: '12px',
  padding: '10px',
  height: 20,
  borderBottom: 'none',
  padding: '0px 10px',
  width: '30px',
}));

const BodyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const TableMainBody = ({
  rows,
  columns,
  selectedIds,
  setSelectedIds,
  tableColumnExtensions,
  dataProviders,
  onRowClick,
  isUnits,
}) => {
  return (
    <React.Fragment>
      <TableBody>
        {rows?.length > 0 ? (
          rows?.map((row, rowIndex) => {
            const id = row?.id;
            const odd = rowIndex % 2 === 1;
            const isRowSelected = selectedIds?.includes(id);
            const onChange = () => {
              const ids = isRowSelected
                ? selectedIds?.filter((ele) => ele !== id)
                : [...selectedIds, id];
              setSelectedIds(ids);
            };

            return (
              <BodyRow
                key={rowIndex}
                sx={{
                  borderBottom: '1px solid #D8D6DE',
                }}
              >
                <BodyCell sx={{ padding: '2px 10px' }}>
                  <CustomCheckbox
                    checked={Boolean(isRowSelected)}
                    onChange={onChange}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: isRowSelected
                          ? 'primary.main'
                          : 'primary.lightGray',
                      },
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                      '&.MuiCheckbox-root': {
                        color: 'primary.lightGray',
                      },
                    }}
                  />
                </BodyCell>
                {columns?.map((col, colIndex) => {
                  const columnExtension = tableColumnExtensions?.find(
                    (item) => item?.columnName === col?.name
                  );
                  const width = columnExtension?.width || 100;
                  const dataProvider = dataProviders?.find(
                    (provider) => provider?.columnName[0] === col?.name
                  );
                  return (
                    <BodyCell
                      key={colIndex}
                      color={'primary.gray'}
                      width={width}
                      style={{
                        minWidth: '100%',
                        maxWidth: width,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Montserrat',
                        userSelect: 'none',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (col?.name !== 'action' && isUnits) {
                          onRowClick(row);
                        }
                      }}
                      sx={{
                        cursor:
                          col?.name !== 'action' && isUnits
                            ? 'pointer'
                            : 'unset',
                      }}
                    >
                      {dataProvider !== undefined
                        ? dataProvider?.func({ row: row })
                        : row[col?.name]}
                    </BodyCell>
                  );
                })}
              </BodyRow>
            );
          })
        ) : (
          <BodyRow>
            <BodyCell
              colSpan={columns.length}
              sx={{ textAlign: 'center', py: 3 }}
            >
              <DescriptionIcon fontSize='large' color='info' />
              <Typography
                sx={{
                  mt: 2,
                  color: 'black',
                  whiteSpace: 'nowrap',
                  fontSize: '0.875rem',
                  fontFamily: 'Montserrat',
                }}
              >
                No data found.
              </Typography>
            </BodyCell>
          </BodyRow>
        )}
      </TableBody>
    </React.Fragment>
  );
};

export default TableMainBody;
