// Library imports
import React, { useState } from "react";
import { Box, TextField, Autocomplete, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

export const CustomDropdown = ({ label, options, value, setValue, error }) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleClearSelection = () => {
    setValue(null);
    setInputValue("");
    setOpen(false);
  };

  const handleToggleDropdown = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box>
      <Typography
        fontSize={"12px"}
        color={error ? "primary.danger" : "primary.gray"}
        textTransform={"capitalize"}
      >
        {label}
      </Typography>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setOpen(false);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        id="custom-controllable-autocomplete"
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select an option"
            InputProps={{
              ...params.InputProps,
              type: "text",
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {value && (
                    <IconButton
                      aria-label="clear selection"
                      edge="end"
                      onClick={handleClearSelection}
                      disableRipple
                      disableFocusRipple
                      sx={{
                        // color: "red",
                        padding: "4px",
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="toggle dropdown"
                    edge="end"
                    onClick={handleToggleDropdown}
                    disableRipple
                    disableFocusRipple
                    sx={{ padding: "4px" }}
                  >
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px",
                paddingRight: "40px",
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderWidth: "1px",
                  borderColor: "black",
                },
              },
            }}
          />
        )}
        sx={{
          "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
            {
              paddingRight: "20px",
            },
          "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot": {
            paddingRight: "20px",
          },
        }}
      />
    </Box>
  );
};
