// Library import
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

// Local import
import {
  TextInput,
  CustomButton,
  CustomSnackbar,
} from '../../../components/shared';
import { forgotPassword } from '../../../redux/reducers/auth/forgotPassword';
import { validateEmail } from '../../../utils/validator';

const ForgotPassword = () => {
  const baseURL = window.location.origin;
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [errorsShow, setErrorsShow] = useState(false);
  const forgetPassword = useSelector((state) => state?.forgetPassword);
  const [formData, setFormData] = useState({
    email: '',
    url: `${baseURL}/reset-password`,
  });

  // function for validate errors
  const validateValues = (formData) => {
    let errors = {};

    if (formData.email && !validateEmail(formData?.email)) {
      errors.email = 'Invalid email format. Please enter a valid email.';
    }
    return errors;
  };

  // Login button disabled
  const disabled = formData.email === '' ? true : false;

  // Handle input change function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // clear errors if errors not present
    if (name === 'email' && errors?.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }
  };

  // Handle submit function in the Login component
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateValues(formData);
    setErrors(validationErrors);
    const errors = Object?.keys(validationErrors)?.length > 0;
    if (!errors) {
      const resultAction = await dispatch(
        forgotPassword({
          email: formData.email.trim(),
          url: formData.url.trim(),
        })
      );

      setErrorsShow({
        isError: resultAction?.error ? true : false,
        success: resultAction?.payload?.success,
        message: resultAction?.errors
          ? resultAction?.payload
          : resultAction?.payload?.message,
      });
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (reason === 'closeButtonClick') {
      setErrorsShow({
        isError: false,
        message: '',
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: '',
      });
    }, 2000);
  };

  return (
    <>
      <CustomSnackbar
        open={
          errorsShow?.isError
            ? errorsShow?.isError
            : errorsShow?.success && errorsShow?.success
        }
        error={errorsShow?.isError ? true : false}
        success={errorsShow?.success ? true : false}
        message={errorsShow?.message}
        handleClose={handleClose}
      />
      <Box
        component={'form'}
        onSubmit={handleSubmit}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        marginTop={{ lg: 4 }}
        paddingX={{ xxs: '10px', md: '5px', lg: '10px', xl: '40px' }}
        marginX={'auto'}
        width={{ sm: '350px', lg: 'auto' }}
      >
        <Box paddingBottom={'5px'} textAlign={{ xxs: 'center', lg: 'left' }}>
          <Typography
            variant='h5'
            color='primary.darkGray'
            fontWeight={'bold'}
            paddingBottom={'5px'}
            fontFamily={'Montserrat'}
            fontSize={'24px'}
          >
            Please Enter Your Email
          </Typography>
          <Typography
            variant='base'
            color='primary.gray'
            fontFamily={'Montserrat'}
            fontSize={'14px'}
          >
            Please enter your email address to initiate the password reset
            process.
          </Typography>
        </Box>

        <TextInput
          name={'email'}
          label='Email'
          placeholder={'johndoe@gmail.com'}
          onChange={handleInputChange}
          value={formData.email}
          error={errors?.email}
        />

        <CustomButton
          disabled={disabled}
          variant={'contained'}
          type={'submit'}
          loading={forgetPassword?.loading}
        >
          <Typography>Send</Typography>
        </CustomButton>

        {/* <Typography
        variant='subtitle'
        color='primary.gray'
        sx={{ userSelect: 'none' }}
        fontFamily={'Montserrat'}
        fontSize={'14px'}
      >
        New on our platform?{'  '}
        <CustomLink href={'/signup'}>Sign up</CustomLink>
      </Typography> */}
      </Box>
    </>
  );
};

export default ForgotPassword;
