import * as React from "react";

export const SharedDataIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6 5.5875C15.6187 5.625 15.6375 5.6625 15.675 5.7C15.75 5.775 15.75 5.925 15.75 6V15C15.75 16.275 14.775 17.25 13.5 17.25H4.5C3.225 17.25 2.25 16.275 2.25 15V3C2.25 1.725 3.225 0.75 4.5 0.75H10.5C10.575 0.75 10.725 0.75 10.8 0.825C10.875 0.825 10.95 0.9 11.025 0.975L15.525 5.475C15.5625 5.5125 15.5813 5.55 15.6 5.5875ZM13.2 5.25L11.25 3.3V5.25H13.2ZM13.5 15.75H4.5C4.05 15.75 3.75 15.45 3.75 15V3C3.75 2.55 4.05 2.25 4.5 2.25H9.75V6C9.75 6.45 10.05 6.75 10.5 6.75H14.25V15C14.25 15.45 13.95 15.75 13.5 15.75ZM12 10.5C12.45 10.5 12.75 10.2 12.75 9.75C12.75 9.3 12.45 9 12 9H6C5.55 9 5.25 9.3 5.25 9.75C5.25 10.2 5.55 10.5 6 10.5H12ZM12.75 12.75C12.75 13.2 12.45 13.5 12 13.5H6C5.55 13.5 5.25 13.2 5.25 12.75C5.25 12.3 5.55 12 6 12H12C12.45 12 12.75 12.3 12.75 12.75ZM6 6C5.55 6 5.25 6.3 5.25 6.75C5.25 7.2 5.55 7.5 6 7.5H7.5C7.95 7.5 8.25 7.2 8.25 6.75C8.25 6.3 7.95 6 7.5 6H6Z"
      fill="black"
    />
    <mask
      id="mask0_225_13346"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={0}
      width={14}
      height={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 5.5875C15.6187 5.625 15.6375 5.6625 15.675 5.7C15.75 5.775 15.75 5.925 15.75 6V15C15.75 16.275 14.775 17.25 13.5 17.25H4.5C3.225 17.25 2.25 16.275 2.25 15V3C2.25 1.725 3.225 0.75 4.5 0.75H10.5C10.575 0.75 10.725 0.75 10.8 0.825C10.875 0.825 10.95 0.9 11.025 0.975L15.525 5.475C15.5625 5.5125 15.5813 5.55 15.6 5.5875ZM13.2 5.25L11.25 3.3V5.25H13.2ZM13.5 15.75H4.5C4.05 15.75 3.75 15.45 3.75 15V3C3.75 2.55 4.05 2.25 4.5 2.25H9.75V6C9.75 6.45 10.05 6.75 10.5 6.75H14.25V15C14.25 15.45 13.95 15.75 13.5 15.75ZM12 10.5C12.45 10.5 12.75 10.2 12.75 9.75C12.75 9.3 12.45 9 12 9H6C5.55 9 5.25 9.3 5.25 9.75C5.25 10.2 5.55 10.5 6 10.5H12ZM12.75 12.75C12.75 13.2 12.45 13.5 12 13.5H6C5.55 13.5 5.25 13.2 5.25 12.75C5.25 12.3 5.55 12 6 12H12C12.45 12 12.75 12.3 12.75 12.75ZM6 6C5.55 6 5.25 6.3 5.25 6.75C5.25 7.2 5.55 7.5 6 7.5H7.5C7.95 7.5 8.25 7.2 8.25 6.75C8.25 6.3 7.95 6 7.5 6H6Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_225_13346)">
      <rect width={18} height={18} fill="currentColor" />
    </g>
  </svg>
);
