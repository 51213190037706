// Library import
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Local import
import { CustomButton } from '../../../components/shared';
import { CustomTabPanel } from '../../../components/shared';
import { a11yProps } from '../../../utils/allyProps';
import OrganizationInformation from './OrganizationInformation';
import AdminInformation from './AdminInformation';
import Subscription from './Subsrciption';
import { addOrganization } from '../../../redux/reducers/organizations/addOrganizationReducer';
import OrganizationsListing from '../OrganizationsListing';
import { subsciptionListing } from '../../../redux/reducers/organizations/subscriptionsListing';
import { CustomSnackbar } from '../../../components/shared';

const AddOrganizations = () => {
  const subsciption = useSelector((state) => state?.sub);
  const addOrganizationState = useSelector((state) => state?.addOrganization);
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const mode = searchParams.get('mode');

  const location = useLocation();
  const editData = location.state?.editData;
  const isEdit = location.state?.isEdit;
  const [errorsShow, setErrorsShow] = useState(false);
  const dispatch = useDispatch();
  // From Data
  const [formData, setFormData] = useState({
    organization: {
      title: '',
      description: '',
      address: '',
      city: '',
      state: '',
      phone: '',
      zip: '',
      status: '1',
      registration_number: '',
      avatar:''
    },
    admin: {
      fname: '',
      lname: '',
      userName: '',
      email: '',
      phone: '',
    },
    subscription: {
      billing_start_date: '',
      setup_fee: '',
      setup_fee_start_date: '',
      subscription_id: '',
      name: '',
      user_limit: '',
      price_per_license: '',
    },
  });

  // Loading state
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (errorsShow?.success) {
      navigate('/organizations');
    }
  }, [errorsShow]);
  // Tab value state
  const [value, setValue] = useState(0);

  // Login button disabled
  const disabled =
    formData?.organization?.title === '' ||
    formData?.organization?.description === '' ||
    formData?.organization?.address === '' ||
    formData?.organization?.state === '' ||
    formData?.organization?.phone === '' ||
    formData?.organization?.zip === '' ||
    formData?.admin?.fname === '' ||
    formData?.admin?.lastName === '' ||
    formData?.admin?.userName === '' ||
    formData?.admin?.email === '' ||
    formData?.admin?.phone === '' ||
    formData?.subscription?.subscription_id === '' ||
    formData?.subscription?.billing_start_date === '' ||
    formData?.subscription?.setup_fee === '' ||
    formData?.subscription?.setup_fee_start_date === ''
      ? true
      : false;
  // HandleChange for Tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // HandleChange for inputs
  const handleInputChange = (section) => (e) => {
    e.preventDefault();
    const { name, value,files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: name === 'avatar' ? files[0] : value,
      },
    }));
  };

  // HandleChange for switch
  const handleSwitch = (event) => {
    setFormData((prev) => ({
      ...prev,
      organization: {
        ...prev,
        status: event.target.checked,
      },
    }));
  };

  // HandleChange for Subscription
  const handleSubsciption = (data) => {
    setFormData((prev) => ({
      ...prev,
      subscription: {
        ...data,
      },
    }));
  };

  // Set Edit data
  useEffect(() => {
    dispatch(subsciptionListing());
    if (isEdit) {
      if (editData) {
        setFormData({
          organization: {
            title: editData?.title,
            description: editData?.description,
            address: editData?.address,
            city: editData?.city,
            state: editData?.state,
            phone: editData?.phone,
            zip: editData?.zip,
            status: editData?.status,
          },
          admin: {
            fname: editData?.fname,
            lname: editData?.lname,
            userName: editData?.userName,
            email: editData?.email,
            phone: editData?.phone,
          },
          subscription: {
            name: editData?.subscriptionName,
          },
        });
      } else {
        navigate('/organizations');
      }
    }
  }, [isEdit]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (reason === 'closeButtonClick') {
      setErrorsShow({
        isError: false,
        message: '',
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: '',
      });
    }, 2000);
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  const formDataObj = new FormData();

  formDataObj.append('title', formData?.organization?.title);
  formDataObj.append('description', formData.organization.description);
  formDataObj.append('address', formData.organization.address);
  formDataObj.append('city', formData.organization.city);
  formDataObj.append('state', formData.organization.state);
  formDataObj.append('phone', formData.organization.phone);
  formDataObj.append('zip', formData.organization.zip);
  formDataObj.append('status', formData.organization?.status);
  formDataObj.append(
    'registration_number',
    formData.organization?.registration_number
  );
  if (formData.organization?.avatar) {
    formDataObj.append('avatar', formData.organization?.avatar);
  }
  formDataObj.append('admin_info[fname]', formData.admin.fname);
  formDataObj.append('admin_info[lname]', formData.admin.lname);
  formDataObj.append('admin_info[username]', formData.admin.userName);
  formDataObj.append('admin_info[email]', formData.admin.email);
  formDataObj.append('admin_info[phone]', formData.admin.phone);

  formDataObj.append('subscription[name]', formData.subscription.name);
  formDataObj.append(
    'subscription[billing_start_date]',
    formData.subscription.billing_start_date
  );
  formDataObj.append(
    'subscription[setup_fee]',
    formData.subscription.setup_fee
  );
  formDataObj.append(
    'subscription[setup_fee_start_date]',
    formData.subscription.setup_fee_start_date
  );
  formDataObj.append(
    'subscription[subscription_id]',
    formData.subscription.subscription_id
  );
  try {
    const resultAction = await dispatch(addOrganization({ data: formDataObj }));

    setErrorsShow({
      isError: resultAction?.error ? true : false,
      message: resultAction?.payload,
      success: resultAction?.payload?.success,
    });
  } catch (error) {
    console.error('Error submitting form:', error);
  }
};


  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message?.message || errorsShow?.message}
        handleClose={handleClose}
      />

      <Box>
        {/* Heading  */}
        <Box
          display={'flex'}
          alignItems={'center'}
          bgcolor={'white'}
          height={'49px'}
          borderBottom={'0.5px solid rgba(161, 164, 172, 0.5)'}
          // mx={'20px'}
          // mt={'20px'}
          // borderRadius={'5px'}
          overflow={'auto'}
          pr={'30px'}
        >

          {/* Tabs Headings */}
          <Box sx={{ borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              // sx={{
              //   borderBottom: 'none',:
              //   '& .MuiTabs-indicator': {
              //     display: 'none',
              //   },
              // }}
            >
              <Tab label='Organization Info' {...a11yProps(0)} />
              <Tab label='Admin Info' {...a11yProps(1)} />
              <Tab label='Subscription' {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>

        {/* Body  */}
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          style={{ height: 'calc(100vh - 110px)' }}
          component={'form'}
          onSubmit={handleSubmit}
        >
          <Box overflow={'auto'}>
            {/* Tabs Data  */}
            {/* Organization Information tab data */}
            <CustomTabPanel value={value} index={0}>
              <OrganizationInformation
                handleInputChange={handleInputChange}
                formData={formData}
                setFormData={setFormData}
                handleSwitch={handleSwitch}
                isEdit={isEdit}
              />
            </CustomTabPanel>

            {/* Admin Information tab data */}
            <CustomTabPanel value={value} index={1}>
              <AdminInformation
                handleInputChange={handleInputChange}
                formData={formData}
                isEdit={isEdit}
              />
            </CustomTabPanel>

            {/* Subscription tab data */}
            <CustomTabPanel value={value} index={2}>
              <Subscription
                handleSubsciption={handleSubsciption}
                formData={formData}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
              />
            </CustomTabPanel>
          </Box>

          {/* Footer   */}
          <Box
            bgcolor={'white'}
            height={{ xs: '62px' }}
            display={'flex'}
            flexDirection={{ xxs: 'column-reverse', xs: 'row' }}
            justifyContent={'end'}
            alignItems={'center'}
            px={'20px'}
            py={{ xxs: '10px', xs: '0px' }}
            gap={2}
          >
            <CustomButton
              sx={{ width: { xxs: '100%', xs: 'fit-content' } }}
              onClick={() => navigate('/organizations')}
              variant={'outlined'}
            >
              <Typography>Cancel</Typography>
            </CustomButton>

            <CustomButton
              sx={{
                width: { xxs: '100%', xs: isEdit ? 'fit-content' : '200px' },
              }}
              type={'submit'}
              disabled={disabled}
              variant={'contained'}
              loading={addOrganizationState?.loading}
            >
              <Typography component={'p'} lineHeight='16px'>
                {isEdit ? 'Edit Organization' : 'Create Organization'}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddOrganizations;
