// Library imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Local imports
import axiosInstance from '../../network/api/http';
import endPoints from '../../network/api/constant';

// Initial State
const initialState = { response: null, loading: false, error: null };

// Listing Api
export const organizationListing = createAsyncThunk(
  'organizationListing',
  async (values, { rejectWithValue }) => {
    const {pageNumber, pageSize} = values
    let queryParams = [];
  if (pageNumber) {
    queryParams.push(`page=${pageNumber}`);
  }
  if (pageSize !== undefined) {
    queryParams.push(`rowsPerPage=${pageSize}`);
  }
  let endPoint = endPoints.organizationListing;
  if (queryParams.length > 0) {
    endPoint += '?' + queryParams.join('&');
  }
    try {
      const response = await axiosInstance.get(endPoint);
      const data = response?.data?.data?.organizations;
      const pagination = response?.data?.data?.meta;

      const organizations = data?.map((data, index) => {
        return {
          id: index + 1,
          srNo: index + 1,
          name: data?.title,
          adminName: data?.user_info?.username
            ? data.user_info.username.charAt(0).toUpperCase() +
              data.user_info.username.slice(1)
            : '',
          registrationNumber: data?.registration_number,
          description: data?.description,
          address: `${data?.city || ''} ${'  '}${data?.state || ''}`,
          adminEmail: data?.user_info?.email,
          phoneNumber: data?.phone,
          uuid: data?.uuid,
          status: data?.subscription_details[0]?.status,
          avatar: data?.avatar,
        };
      });

      return { organizations, meta: pagination };
    } catch (error) {
      console.error('Add failed:', error);
      if (error.response) {
        return rejectWithValue(error.response.data || 'An error occurred');
      } else {
        return rejectWithValue('Network error: Please check your connection.');
      }
    }
  }
);


const organizationListingSlice = createSlice({
  name: 'organizationListing',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(organizationListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(organizationListing.fulfilled, (state, action) => {
        state.loading = false;
       state.response = {
         organizations: action.payload.organizations,
         meta: action.payload.meta,
       };
      })
      .addCase(organizationListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to log in';
      });
  },
});

export default organizationListingSlice.reducer;
