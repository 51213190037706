import * as React from "react";

export const TaskIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 9C14.25 8.55 14.55 8.25 15 8.25C15.45 8.25 15.75 8.55 15.75 9V14.25C15.75 15.525 14.775 16.5 13.5 16.5H3C1.725 16.5 0.75 15.525 0.75 14.25V3.75C0.75 2.475 1.725 1.5 3 1.5H11.25C11.7 1.5 12 1.8 12 2.25C12 2.7 11.7 3 11.25 3H3C2.55 3 2.25 3.3 2.25 3.75V14.25C2.25 14.7 2.55 15 3 15H13.5C13.95 15 14.25 14.7 14.25 14.25V9ZM8.775 11.025L17.025 2.775C17.325 2.475 17.325 2.025 17.025 1.725C16.725 1.425 16.275 1.425 15.975 1.725L8.25 9.45L6.525 7.725C6.225 7.425 5.775 7.425 5.475 7.725C5.175 8.025 5.175 8.475 5.475 8.775L7.725 11.025C7.875 11.175 8.025 11.25 8.25 11.25C8.475 11.25 8.625 11.175 8.775 11.025Z"
      fill="black"
    />
    <mask
      id="mask0_225_13348"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={1}
      width={18}
      height={16}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 9C14.25 8.55 14.55 8.25 15 8.25C15.45 8.25 15.75 8.55 15.75 9V14.25C15.75 15.525 14.775 16.5 13.5 16.5H3C1.725 16.5 0.75 15.525 0.75 14.25V3.75C0.75 2.475 1.725 1.5 3 1.5H11.25C11.7 1.5 12 1.8 12 2.25C12 2.7 11.7 3 11.25 3H3C2.55 3 2.25 3.3 2.25 3.75V14.25C2.25 14.7 2.55 15 3 15H13.5C13.95 15 14.25 14.7 14.25 14.25V9ZM8.775 11.025L17.025 2.775C17.325 2.475 17.325 2.025 17.025 1.725C16.725 1.425 16.275 1.425 15.975 1.725L8.25 9.45L6.525 7.725C6.225 7.425 5.775 7.425 5.475 7.725C5.175 8.025 5.175 8.475 5.475 8.775L7.725 11.025C7.875 11.175 8.025 11.25 8.25 11.25C8.475 11.25 8.625 11.175 8.775 11.025Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_225_13348)">
      <rect width={18} height={18} fill="currentColor" />
    </g>
  </svg>
);
