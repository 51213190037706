// Library import
import React from 'react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Local import
import { TextInput, CustomButton } from '../../../components/shared';
import { validateEmail, validatePassword } from '../../../utils/validator';
import { resetPassword } from '../../../redux/reducers/auth/resetPassword';
import { CustomSnackbar } from '../../../components/shared';

const ResetPassword = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const navigate = useNavigate();
  const token = params.get('token');
  const resetPasswordState = useSelector((state) => state?.resetPassword);
  const [formData, setFormData] = useState({
    email: email,
    token: token,
    password: '',
    confirmPassword: '',
  });

  const [errorsShow, setErrorsShow] = useState(false);

  useEffect(() => {
    if (errorsShow.success) {
      navigate('/login');
    }
  }, [errorsShow]);

  const dispatch = useDispatch();

  // Login button disabled
  const disabled =
    formData.email === '' ||
    formData.password === '' ||
    formData.confirmPassword === ''
      ? true
      : false;

  // usestate for errors
  const [errors, setErrors] = useState({});

  // Loading state
  const [loading, setLoading] = useState(false);

  // function for validate errors
  const validateValues = (formData) => {
    let errors = {};

    // Validate email format
    if (formData.email && !validateEmail(formData?.email)) {
      errors.email = 'Invalid email format. Please enter a valid email.';
    }

    // Validate password format
    if (formData.password && !validatePassword(formData?.password)) {
      errors.password = (
        <Box mt={'10px'} ml={'5px'}>
          <li>Password must be at least 6 characters</li>
          <li>Include an uppercase and lowercase letter</li>
          <li>Include a special character</li>
          <li>Include a number</li>
        </Box>
      );
    }
    // If password format is correct, check if passwords match
    else if (
      formData.password &&
      formData.confirmPassword &&
      formData.password !== formData.confirmPassword
    ) {
      errors.confirmPassword =
        'Passwords do not match. Please verify both entries and try again.';
    }

    return errors;
  };

  // Handle inputchange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'password' && errors.password) {
      setErrors({ ...errors, password: '' });
    }

    if (name === 'confirmPassword' && errors.confirmPassword) {
      setErrors({ ...errors, confirmPassword: '' });
    }
  };

  //  handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateValues(formData);
    setErrors(validationErrors);
    const errors = Object?.keys(validationErrors)?.length > 0;
    if (!errors) {
      const resultAction = await dispatch(
        resetPassword({
          data: {
            email: formData.email,
            password: formData.password.trim(),
            password_confirmation: formData?.confirmPassword.trim(),
            token: formData?.token,
          },
        })
      );
      setErrorsShow({
        isError: resultAction?.error ? true : false,
        message: resultAction?.payload?.message,
        success: resultAction?.payload?.success,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (reason === 'closeButtonClick') {
      setErrorsShow({
        isError: false,
        message: '',
      });
      return;
    }

    setTimeout(() => {
      setErrorsShow({
        isError: false,
        message: '',
      });
    }, 2000);
  };
  return (
    <>
      <CustomSnackbar
        open={errorsShow?.isError}
        error
        message={errorsShow?.message}
        handleClose={handleClose}
      />
      <Box
        component={'form'}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        marginTop={{ lg: 4 }}
        paddingX={{ xxs: '10px', md: '5px', lg: '10px', xl: '40px' }}
        marginX={'auto'}
        width={{ sm: '350px', lg: 'auto' }}
      >
        <Box paddingBottom={'5px'} textAlign={{ xxs: 'center', lg: 'left' }}>
          <Typography
            variant='h5'
            color='primary.darkGray'
            fontWeight={'bold'}
            paddingBottom='5px'
            fontFamily={'Montserrat'}
            fontSize={'24px'}
          >
            Please reset your password
          </Typography>
          <Typography
            variant='base'
            color='primary.gray'
            fontFamily={'Montserrat'}
            fontSize={'14px'}
          >
            Please reset your password to continue.
          </Typography>
        </Box>

        <TextInput
          type={'password'}
          name={'password'}
          label='Password'
          placeholder={'Password'}
          onChange={handleInputChange}
          value={formData.password}
          error={errors?.password}
        />

        <TextInput
          type={'password'}
          name={'confirmPassword'}
          label='Confirm Password'
          placeholder={'Confirm Password'}
          onChange={handleInputChange}
          value={formData?.confirmPassword}
          error={errors?.confirmPassword}
        />

        <CustomButton
          onClick={handleSubmit}
          variant={'contained'}
          sx={{ marginTop: '19px' }}
          disabled={disabled}
          loading={resetPasswordState?.loading}
        >
          <Typography>Reset</Typography>
        </CustomButton>
      </Box>
    </>
  );
};

export default ResetPassword;
